import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

// Import LLB images
import Land_Law from './img/Land_Law.jpg';
import Tort_Law from './img/Tort_Law.jpg';
import Contract_Law from './img/Contract_Law.jpg';
import Criminal_Law from './img/Criminal_Law.jpg';
import Public_Law from './img/Public_Law.jpg';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: '100%',
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    //height: '100%',
  },
  "&:hover, &.Mui-focusVisible": {
    "& .MuiImageBackdrop-root": {
      opacity: 0.9,
      backgroundColor: "#004AAD",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "4px",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  borderRadius: "4px",
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.45,
  transition: theme.transitions.create("opacity"),
  borderRadius: "4px",
}));

const LLB = ({open, onClose}) => {

  return (
    <div style={{}}>

      <Dialog fullScreen open={open} onClose={onClose}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#004AAD' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              LLB Topics Quiz
            </Typography>
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
       
        <Grid container spacing={2} sx={{width:'96%', marginLeft:'1%', marginRight:'1%'}}>
        <Grid item xs={12} sm={6} md={6} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'LAND LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Land_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LAND LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'TORT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Tort_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
            TORT LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'CONTRACT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Contract_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CONTRACT LAW
            </Image>
          </ImageButton>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'CRIMINAL LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Criminal_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CRIMINAL LAW
            </Image>
          </ImageButton>

        </Grid>

        <Grid item xs={12} sm={12} md={4} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'PUBLIC LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Public_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      PUBLIC LAW
            </Image>
          </ImageButton>

        </Grid>
       
      </Grid>
      <br/>
      </Dialog>

    </div>
  );
};

export default LLB;
