import { React, useState } from 'react';
import { Container, Grid, Paper, TextField, Button, Typography, Link } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom'; 
import firebase from './firebase'; 

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();

  const resetPassword = async(e)=>{
    e.preventDefault();
    try{
      await firebase.auth().sendPasswordResetEmail();
        
    } catch (error){
        console.error("there was an error: ", error.message);
      }
    };

  const handleSubmit = async(event) =>{
    event.preventDefault();
    try{
      const response = await firebase.auth().signInWithEmailAndPassword(email, password);
      
      if(response.user){
        setEmail('');
        setPassword('');
        console.log("User logged in successfully!");
        
        await history.push('/Dashboard');
      }
        
    } catch (error){
        console.error("login failed: ", error.message);
        alert("Login attempt failed"); 
      }
    };

  return (
    <Container component="main" maxWidth="xs">
      <Grid container justifyContent="center" alignItems="center" style={{ height: '81vh' }}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h5" align="center" gutterBottom>
              Login
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
                onClick={handleSubmit}
              >
                Login
              </Button>
              <Typography variant="body2" align="center" style={{ marginTop: '10px' }}>
                Haven't got an account yet? <Link component={RouterLink} to="/signup">Sign up here</Link> for exclusive content.
              </Typography>
              <Typography variant="body2" align="center" style={{ marginTop: '5px' }}>
              please email <a href='mailto:info.admin@lawschoolcompass.com'>info.admin@lawschoolcompass.com</a> if you have forgotten your password.
              </Typography>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
