import React from 'react';
import { Typography, Container } from '@mui/material';

const CookiesPolicy = () => {
  return (
    <Container className='text-center' style={{marginLeft:'4%', marginRight:'4%'}}>
      <Typography variant="h4" gutterBottom>
        Cookies Policy
      </Typography>
      <Typography variant="body1" paragraph>
        This Cookie Policy explains how Law School Compass ("we", "us", or
        "our") uses cookies and similar technologies to recognize you when
        you visit our website www.lawschoolcompass.com ("Website"). It
        explains what these technologies are and why we use them, as well as
        your rights to control our use of them.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>What are cookies?</strong>
        <br />
        Cookies are small text files that are stored on your browser or the hard drive of your computer or other device when you visit a website. Cookies contain information that is transferred to your computer's hard drive. 
    </Typography>

    <Typography variant="body1" paragraph>
        <strong>Why do we use cookies?</strong>
        <br />
        We use cookies and similar technologies to recognize your preferences and settings, enhance your user experience, facilitate navigation of our Website, and provide personalized content and advertising. Cookies also help us analyze how our Website is used and improve its performance.
    </Typography>

    <Typography variant="body1" paragraph>
        <strong>Types of cookies we use</strong>
        <br />
        1. Essential Cookies: These cookies are necessary for the operation of our Website. They enable you to move around the Website and use its features. Without these cookies, services you have asked for cannot be provided.
<br/>
2. Performance and Analytics Cookies: These cookies collect information about how visitors use our Website, such as which pages are visited most often, and if users receive error messages from certain pages. These cookies do not collect information that identifies you. All information these cookies collect is aggregated and therefore anonymous. We use this data to improve the performance of our Website.
<br/>
3. Functionality Cookies: These cookies allow our Website to remember choices you make (such as your language preferences) and provide enhanced, more personal features. They may also be used to provide services you have asked for, such as watching a video or commenting on a blog.
<br/>
4. Advertising Cookies: These cookies are used to deliver advertisements that are more relevant to you and your interests. They may be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. These cookies remember what you have looked at on the Website and we may share this information with other organizations, such as advertisers.
    </Typography>

    <Typography variant="body1" paragraph>
        <strong>How to control cookies</strong>
        <br />
        You can control and manage cookies in various ways. Please note that removing or blocking cookies may affect your user experience and some parts of our Website may no longer be accessible or function properly.
        <br/>
        - Browser Settings: You can set your browser to refuse all or some browser cookies or to alert you when websites set or access cookies. Please consult the help section of your browser or visit www.allaboutcookies.org for more information.
        <br/>
        - Third-Party Tools:*Some third-party tools, such as Google Analytics, allow you to opt-out of their tracking technologies.
    </Typography>

    <Typography variant="body1" paragraph>
        <strong>Changes to our Cookie Policy</strong>
        <br />
        We may update this Cookie Policy from time to time. Any changes we make will be posted on this page with an updated revision date. 
     </Typography>
     <Typography variant="body1" paragraph>
        <strong>Contact Us</strong>
        <br />
        If you have any questions or concerns about our use of cookies, please contact us at <a href="mailto:info.admin@lawschoolcompass.com">info.admin@lawschoolcompasss.com</a>
        </Typography>

      <Typography variant="body1" paragraph>
        By using our Website, you consent to our use of cookies as described
        in this Cookie Policy. This Cookie Policy was last updated in March
        2024.
      </Typography>
    </Container>
  );
};

export default CookiesPolicy;
