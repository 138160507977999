import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const WelcomeSnackbar = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const once = localStorage.getItem('once');
      if (!once) {
        setOpen(true);
        localStorage.setItem('once', 'true');
      }
    }, 100); // Delay opening Snackbar by 100 milliseconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const linkStyles = {
    color: 'white', // Set the link text color to white
  };

  return (
    <Snackbar open={open} onClose={handleClose}>
      <div>
        <Alert
          action={[]}
          onClose={handleClose}
          severity="info"
          style={{ backgroundColor: '#004AAD' }} // Set background color
        >
          By using Law School Compass, you agree to our <a href={'./CookiesPolicy'} style={linkStyles}>Cookies Policy</a>, <a href='./PrivacyPolicy' style={linkStyles}>Privacy Policy</a>, <a href={'./SocialMediaPolicy'} style={linkStyles}>Social Media Policy</a>, & <a href={'./WebsiteTermsAndConditions'} style={linkStyles}>Website Terms of Use</a>
          <Button color="inherit" size="small" onClick={handleClose}>
            OK
          </Button>
        </Alert>
      </div>
    </Snackbar>
  );
};

export default WelcomeSnackbar;
