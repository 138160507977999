import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Button, Container, Typography, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, RadioGroup, FormControl, Radio, Checkbox, Stepper, Step, StepLabel } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import 'firebase/firestore';
import firebase from '../firebase'; 

const questions = require("./flk1mock2part2.json").slice(0,90);

const confidentialityAgreement = `
  By participating in this exam, you agree to keep all exam content confidential. 
  You must not share, distribute, or discuss the exam questions or answers with anyone.
`;

const sitAgreement = `
  You agree to sit for the entire duration of the exam unless an emergency arises. 
  You must not use any external help or resources during the exam.
`;

function Exam() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [timer, setTimer] = useState(180 * 60); // 180 minutes in seconds
  const [progress, setProgress] = useState(100); // Initial progress
  const [score, setScore] = useState(0); // Overall score
  const [selectedAnswers, setSelectedAnswers] = useState(Array(questions.length).fill(null)); // State to track selected answers
  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility
  const [tagScores, setTagScores] = useState({}); // Scores per tag
  const [agreementsAccepted, setAgreementsAccepted] = useState(false); // Track if agreements are accepted
  const [activeStep, setActiveStep] = useState(0); // Track active step in Stepper
  const [confidentialityChecked, setConfidentialityChecked] = useState(false); // Track confidentiality agreement checkbox
  const [sitChecked, setSitChecked] = useState(false); // Track sit agreement checkbox
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false); // State to control submit confirmation dialog
  const [flaggedQuestions, setFlaggedQuestions] = useState([]); // State to track flagged questions

  const intervalRef = useRef(null); // Reference to the interval

  // Function to handle timer countdown
  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer > 0) {
          setProgress((prevTimer / (180 * 60)) * 100); // Calculate progress based on remaining time
          return prevTimer - 1;
        } else {
          clearInterval(intervalRef.current);
          setDialogOpen(true); // Show dialog when timer reaches 0
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (agreementsAccepted) {
      startTimer();
    }

    // Cleanup function
    return () => clearInterval(intervalRef.current);
  }, [agreementsAccepted]);

  // Function to handle "Next" button click
  const handleNextButtonClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
      setCurrentQuestionNumber(prevNumber => prevNumber + 1);
    } else {
      setSubmitDialogOpen(true); // Show submit confirmation dialog at the end of the exam
    }
  };

  // Function to handle "Previous" button click
  const handlePreviousButtonClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => prevIndex - 1);
      setCurrentQuestionNumber(prevNumber => prevNumber - 1);
    }
  };

  // Function to handle retry
  const handleRetry = () => {
    setCurrentQuestionIndex(0);
    setCurrentQuestionNumber(1);
    setDialogOpen(false);
    setSubmitDialogOpen(false);
    setScore(0);
    setSelectedAnswers(Array(questions.length).fill(null));
    setTimer(180 * 60);
    setProgress(100);
    setTagScores({});
    setFlaggedQuestions([]); // Reset flagged questions
    clearInterval(intervalRef.current); // Clear any existing interval
    startTimer(); // Start the timer again
  };

  // Function to handle agreement step navigation
  const handleAgreementNext = () => {
    if (activeStep === 0 && confidentialityChecked) {
      setActiveStep(1);
    } else if (activeStep === 1 && sitChecked) {
      setAgreementsAccepted(true);
    }
  };

  // Function to handle final submission of answers
  const handleSubmit = async(event) => {
    event.preventDefault();

    let calculatedScore = 0;
    const calculatedTagScores = {};

    selectedAnswers.forEach((selectedAnswer, index) => {
      const question = questions[index];
      const isCorrect = selectedAnswer === question.answer;
      const tag = question.tag;

      if (isCorrect) {
        calculatedScore += 1;
      }

      if (!calculatedTagScores[tag]) {
        calculatedTagScores[tag] = { correct: 0, total: 0 };
      }
      calculatedTagScores[tag].total += 1;
      if (isCorrect) {
        calculatedTagScores[tag].correct += 1;
      }
    });

    setScore(calculatedScore);
    console.log(calculatedTagScores);
    setTagScores(calculatedTagScores);
    setSubmitDialogOpen(false);
    setDialogOpen(true);
    clearInterval(intervalRef.current); // Stop the timer

    const user = firebase.auth().currentUser;
    await firebase.database().ref("users").child(user.uid + "/flk1Mock/partTwoResults").push({
      score: calculatedScore,
      calculatedTagScores
    });
  };

  // Function to handle flagging questions
  const handleFlagQuestion = () => {
    setFlaggedQuestions(prevFlaggedQuestions => {
      if (prevFlaggedQuestions.includes(currentQuestionIndex)) {
        return prevFlaggedQuestions.filter(index => index !== currentQuestionIndex);
      } else {
        return [...prevFlaggedQuestions, currentQuestionIndex];
      }
    });
  };

  return (
    <Container maxWidth="md" sx={{ mt: 3, minHeight: "78vh", display: "flex", flexDirection: "column" }}>
      {!agreementsAccepted ? (
        <Dialog open={!agreementsAccepted}>
          <DialogTitle>User Agreements</DialogTitle>
          <DialogContent>
            <Stepper activeStep={activeStep}>
              {['Confidentiality Agreement', 'Sit Agreement'].map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <Box sx={{ overflow: 'auto', height: 200, mt: 2 }}>
                <Typography variant="body1" gutterBottom>{confidentialityAgreement}</Typography>
                <FormControlLabel
                  control={<Checkbox checked={confidentialityChecked} onChange={(e) => setConfidentialityChecked(e.target.checked)} />}
                  label="I agree to the confidentiality agreement"
                />
              </Box>
            ) : (
              <Box sx={{ overflow: 'auto', height: 200, mt: 2 }}>
                <Typography variant="body1" gutterBottom>{sitAgreement}</Typography>
                <FormControlLabel
                  control={<Checkbox checked={sitChecked} onChange={(e) => setSitChecked(e.target.checked)} />}
                  label="I agree to the sit agreement"
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {activeStep > 0 && <Button onClick={() => setActiveStep(activeStep - 1)}>Back</Button>}
            <Button onClick={handleAgreementNext} disabled={(activeStep === 0 && !confidentialityChecked) || (activeStep === 1 && !sitChecked)}>
              {activeStep === 1 ? 'Start Exam' : 'Next'}
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <>
          <div className="jumbotron text-center">
            <Typography variant="h4" gutterBottom>(2)FLK1 Mock Exam: Part 2</Typography>
          </div>
          <Typography variant="h6" gutterBottom id="timer">
            Timer: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 2 }} />
          <Typography variant="h6" gutterBottom id="timer">
            Question {currentQuestionNumber}
          </Typography>

          <Typography variant="body1" gutterBottom>{questions[currentQuestionIndex].question}</Typography>
          <form id="quiz">
            <FormControl component="fieldset">
              <RadioGroup
                name="answer"
                value={selectedAnswers[currentQuestionIndex]}
                onChange={(e) => {
                  const newAnswers = [...selectedAnswers];
                  newAnswers[currentQuestionIndex] = e.target.value;
                  setSelectedAnswers(newAnswers);
                }}
              >
                {questions[currentQuestionIndex].options.map((option, index) => (
                  <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                ))}
              </RadioGroup>
            </FormControl>
          </form>
          <Grid container>

            <Grid item xs={4} md={4} lg={4}>
          <Button variant="contained" color="primary" onClick={handlePreviousButtonClick} disabled={currentQuestionIndex === 0} sx={{ mt: 2, width:'95%' }}>
            Previous
          </Button>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
          <Button variant="contained" color="secondary" onClick={handleFlagQuestion} sx={{ mt: 2, width:'95%', backgroundColor:'black' }}>
          <FlagIcon/> {flaggedQuestions.includes(currentQuestionIndex) ? 'Unflag' : 'Flag'} Question
          </Button>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
          <Button variant="contained" color="primary" onClick={handleNextButtonClick} sx={{ mt: 2, width:'95%'}}>
            {currentQuestionIndex === questions.length - 1 ? 'Finish Exam' : 'Next'}
          </Button>
          </Grid>

          </Grid>

          <Typography variant="h6" sx={{ mt: 3 }}><FlagIcon/>Flagged Questions:</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {flaggedQuestions.map(index => (
              <Button key={index} variant="outlined" onClick={() => setCurrentQuestionIndex(index)} sx={{color:'black', border:'1px solid black'}}>
                {index + 1}
              </Button>
            ))}
          </Box>

          <Dialog open={submitDialogOpen}>
            <DialogTitle>Submit Exam</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to submit your answers?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSubmitDialogOpen(false)}>Return to Questions</Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={dialogOpen}>
            <DialogTitle>Exam Completed</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>Your score: {score}/{questions.length}</Typography>
              {Object.keys(tagScores).map(tag => {
                const { correct, total } = tagScores[tag];
                const percentage = (correct / total) * 100;
                return (
                  <Box key={tag} sx={{ mb: 2 }}>
                    <Typography variant="body2" gutterBottom>{tag}: {percentage}%</Typography>
                    <LinearProgress variant="determinate" value={percentage} sx={{ mb: 1 }} />
                    <Typography variant="body2" color={percentage < 60 ? 'error' : 'primary'}>
                      {percentage < 60 ? 'You need to improve in this area' : 'You have done well for this area'}
                    </Typography>
                  </Box>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {window.location.href='/Dashboard'}} color="primary">Dashboard</Button>
              <Button onClick={handleRetry} color="primary">Retry</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
}

export default Exam;
