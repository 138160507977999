import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CardActionArea, CardActions } from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";

// Import LLB images
import Land_Law from './img/Land_Law.jpg';
import Tort_Law from './img/Tort_Law.jpg';
import Contract_Law from './img/Contract_Law.jpg';
import Criminal_Law from './img/Criminal_Law.jpg';
import Public_Law from './img/Public_Law.jpg';

const imagePaths = {
  'Land_Law.jpg': Land_Law,
  'Tort_Law.jpg': Tort_Law,
  'Contract_Law.jpg': Contract_Law,
  'Criminal_Law.jpg': Criminal_Law,
  'Public_Law.jpg': Public_Law,
};
const jsonData = require("./LLBLearningMaterial.json");

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: '100%',
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    //height: '100%',
  },
  "&:hover, &.Mui-focusVisible": {
    "& .MuiImageBackdrop-root": {
      opacity: 0.9,
      backgroundColor: "#004AAD",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "4px",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  borderRadius: "4px",
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.45,
  transition: theme.transitions.create("opacity"),
  borderRadius: "4px",
}));


const LLB = () => {
  const [openFLK2Quiz, setOpenFLK2Quiz] = React.useState(false);
  const [openFLK2LearningMaterial, setOpenFLK2LearningMaterial] = React.useState(false);

  const handleClickOpenFLK2Quiz = () => {
    setOpenFLK2Quiz(true);
  };

  const handleClickOpenFLK2LearningMaterial = () => {
    setOpenFLK2LearningMaterial(true);
  };

  const handleCloseFLK2Quiz = () => {
    setOpenFLK2Quiz(false);
  };

  const handleCloseFLK2LearningMaterial = () => {
    setOpenFLK2LearningMaterial(false);
  };

  return (
    <div style={{}}>
      <div class="text-center" style={{ marginBottom: '6%' }}>
        <Typography gutterBottom variant="h4" component="div">
        Bachelor of Laws
        </Typography>
        <Typography variant="body1" style={{ marginLeft: '7%', marginRight: '7%' }}>
        LLB (Bachelor of Laws) is an undergraduate program providing a rigorous legal education, spanning diverse areas such as contract law, criminal law, and public law. It fosters critical thinking, legal analysis, and research capabilities, empowering students to understand complex legal frameworks and apply legal principles in various contexts, preparing them for careers in law or further academic pursuits.        </Typography>
      </div>

      <Grid container spacing={2} style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
        <Grid item xs={12} sm={12} md={6} style={{ borderRadius: '4px', padding: 0, marginBottom: '3%' }}>
          <Card sx={{ width: '95%', marginLeft: '2%', marginRight: '2%' }}>
            <CardContent>
              <Typography variant="h5">LLB Topics Quiz</Typography>
              <br />
              <Typography variant="body2">
                Attempt a free 5 question multiple choice quiz, with no timer, to test your knowledge on FLK2 topics, in preparation for your SQE1 exam
              </Typography>
            </CardContent>
            <CardActions onClick={handleClickOpenFLK2Quiz}>
              <Button onClick={handleClickOpenFLK2Quiz}>
                Click here to choose a topic for the quiz
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} style={{ borderRadius: '4px', padding: 0, marginBottom: '3%' }}>
          <Card sx={{ width: '95%', marginLeft: '2%', marginRight: '2%' }}>
            <CardContent>
              <Typography variant="h5">LLB Learning Material</Typography>
              <br />
              <Typography variant="body2">
                Use these learning material to enhance your Functional Legal Knowledge
              </Typography>
            </CardContent>
            <CardActions onClick={handleClickOpenFLK2LearningMaterial}>
              <Button onClick={handleClickOpenFLK2LearningMaterial} disabled>
                COMING SOON{/* Click here to view all the units we cover */}
              </Button>
            </CardActions>
          </Card>
        </Grid>

      </Grid>

      <Dialog fullScreen open={openFLK2Quiz} onClose={handleCloseFLK2Quiz}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#004AAD' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              LLB Topics Quiz
            </Typography>
            <IconButton color="inherit" onClick={handleCloseFLK2Quiz} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
       
        <Grid container spacing={2} sx={{width:'96%', marginLeft:'1%', marginRight:'1%'}}>
        <Grid item xs={12} sm={6} md={6} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'LAND LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Land_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LAND LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'TORT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Tort_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
            TORT LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'CONTRACT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Contract_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CONTRACT LAW
            </Image>
          </ImageButton>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'CRIMINAL LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Criminal_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CRIMINAL LAW
            </Image>
          </ImageButton>

        </Grid>

        <Grid item xs={12} sm={12} md={4} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'PUBLIC LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/quiz";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Public_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      PUBLIC LAW
            </Image>
          </ImageButton>

        </Grid>
       
      </Grid>
      <br/>
      </Dialog>

      <Dialog fullScreen open={openFLK2LearningMaterial} onClose={handleCloseFLK2LearningMaterial}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#004AAD' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              LLB Learning Material
            </Typography>
            <IconButton color="inherit" onClick={handleCloseFLK2LearningMaterial} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
        
        {jsonData.map((item, index) => (

<Paper elevation={4} sx={{width:'96%', mr:'2%', ml:'2%', mb:'2%'}} style={{height:'90%'}}>
<Card key={index} sx={{ display: "flex", mb: 2, height:'100%' }}>
  <CardActionArea>
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <CardMedia
        component="img"
        sx={{width:"25%", ml:'7px', borderRadius:'4px'}}
        image={imagePaths[item.image]}
        alt={item.name}
      />
      <Box sx={{ flex: 1, ml: 2, mr: 2 }}>
        <CardContent sx={{ flex: "1 1 auto" }}>
          <Typography component="div" variant="h5">
            {item.name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {item.price}
          </Typography>
        </CardContent>
        <Box
          sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
        >
          <Typography>{item.description}</Typography>
        </Box>
      </Box>
    </Box>
  </CardActionArea>
</Card>
</Paper>

))}

      </Dialog>
    </div>
  );
};

export default LLB;
