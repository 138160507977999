import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles'; // Import ThemeProvider and createTheme
import Home from './Home';
import NavBar from './NavBar.js';
import Footer from './Footer';
import firebase from './firebase';

// whilst Live

import PrivacyPolicy from './PrivacyPolicy';
import CookiesPolicy from './CookiesPolicy';
import WebsiteTermsAndConditions from './WebsiteTermsAndConditions';
import SocialMediaPolicy from './SocialMediaPolicy';
import ErrorPage from './ErrorPage';
import Login from "./Login";
import SignUp from './SignUp';

import FLK1Mock1Part1 from './MockQuestions/FLK1MockP1.js';
import FLK1Mock1Part2 from './MockQuestions/FLK1MockP2.js';
import FLK1Mock2Part1 from './MockQuestions/FLK1Mock2P1.js';
import FLK1Mock2Part2 from './MockQuestions/FLK1Mock2P2.js';
import FLK2MockPart1 from './MockQuestions/FLK2MockP1.js';
import FLK2MockPart2 from './MockQuestions/FLK2MockP2.js';

import FLK1 from './FLK1';
import FLK2 from './FLK2';
import LLB from './LLB';
//import Articles from './Articles';
import FAQ from './FAQ';
import Dashboard from './Dashboard';
import MySubscription from './Subscription';

// Learning Material
//FLK1
import BusinessFinance from './LearningMaterial/FLK1/BusinessFinance.js';
import BusinessLaw from './LearningMaterial/FLK1/BusinessLaw.js';
import ConstitutionalLaw from './LearningMaterial/FLK1/ConstitutionalLaw.js';
import ContractLaw from './LearningMaterial/FLK1/ContractLaw.js';
import Ethics from './LearningMaterial/FLK1/Ethics.js';
import DisputeResolution from './LearningMaterial/FLK1/DisputeResolution.js';
import LegalSystems from './LearningMaterial/FLK1/LegalSystems.js';
import LegalService from './LearningMaterial/FLK1/LegalService.js';
import TortLaw from './LearningMaterial/FLK1/TortLaw.js';
import HumanRightsAct from './LearningMaterial/FLK1/HumanRightsAct.js';
import RetainedEULaw from './LearningMaterial/FLK1/RetainedEULaw.js';
//FLK2
import CriminalLaw from './LearningMaterial/FLK2/CriminalLaw.js';
import CriminalPractice from './LearningMaterial/FLK2/CriminalPractice.js';
import InheritanceTax from './LearningMaterial/FLK2/InheritanceTax.js';
import LandLaw from './LearningMaterial/FLK2/LandLaw.js';
import PropertyPractice from './LearningMaterial/FLK2/PropertyPractice.js';
import SolicitorAccounts from './LearningMaterial/FLK2/SolicitorAccounts.js';
import Trusts from './LearningMaterial/FLK2/Trusts.js';
import WillsAndAdministrationOfEstates from './LearningMaterial/FLK2/WillsAndAdministrationOfEstates.js';

// FLK1 | FLK2 | LLB Quizzes
// | FLK1 |
import BusinessFinanceQuiz from './QuizQuestionsFLK1Topics/BusinessFinanceQuiz';
import BusinessLawQuiz from './QuizQuestionsFLK1Topics/BusinessLawQuiz';
import ConstitutionalLawQuiz from './QuizQuestionsFLK1Topics/ConstitutionalLawQuiz';
import ContractLawQuiz from './QuizQuestionsFLK1Topics/ContractLawQuiz';
import DisputeResolutionQuiz from './QuizQuestionsFLK1Topics/DisputeResolutionQuiz';
import EthicsQuiz from './QuizQuestionsFLK1Topics/EthicsQuiz';
import HumanRightsActQuiz from './QuizQuestionsFLK1Topics/HumanRightsActQuiz';
import LegalServiceQuiz from './QuizQuestionsFLK1Topics/LegalServiceQuiz';
import LegalSystemsQuiz from './QuizQuestionsFLK1Topics/LegalSystemsQuiz';
import RetainedEULawQuiz from './QuizQuestionsFLK1Topics/RetainedEULawQuiz';
import TortLawQuiz from './QuizQuestionsFLK1Topics/TortLawQuiz';
// | FLK2 |
import CriminalLawQuiz from './QuizQuestionsFLK2Topics/CriminalLawQuiz.js';
import CriminalPracticeQuiz from './QuizQuestionsFLK2Topics/CriminalPracticeQuiz.js';
import PropertyPracticeQuiz from './QuizQuestionsFLK2Topics/PropertyPracticeQuiz.js';
import LandLawQuiz from './QuizQuestionsFLK2Topics/LandLawQuiz.js';
import TrustsQuiz from './QuizQuestionsFLK2Topics/TrustsQuiz.js';
import SolicitorsAccountsQuiz from './QuizQuestionsFLK2Topics/SolicitorsAccountsQuiz.js';
import WillsAndAdministrationOfEstatesQuiz from './QuizQuestionsFLK2Topics/WillsAndAdministrationOfEstatesQuiz.js';
import InheritanceTaxQuiz from './QuizQuestionsFLK2Topics/InheritanceTaxQuiz.js';
// | LLB |

// FLK1 | FLK2 | LLB Silver Quizzes & Mocks
// FLK1 | FLK2 | LLB Gold Quizzes & Mocks



const theme = createTheme(); // Create a default theme

function App() {
  const [user, setUser] = useState(null);
  const [planType, setPlanType] = React.useState('');
  const [userPurchasedProducts, setUserPurchasedProducts] = React.useState([]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if(currentUser){
        const userRef = firebase.database().ref("users/"+currentUser.uid);
        userRef.on("value", (snapshot)=>{
          const userVal = snapshot.val()
          if(userVal){
            setPlanType(userVal.subscription.planType)
          }
        });
      }else{
        setPlanType('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

    // Function to fetch user's purchased products from Stripe
    async function fetchUserPurchasedProducts(stripeId) {
      try {
        const response = await fetch(`/api/user/purchased-products?stripeId=${stripeId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user purchased products');
        }
        const data = await response.json();
        setUserPurchasedProducts(data);
      } catch (error) {
        console.error('Error fetching user purchased products:', error);
        throw error;
      }
    }
  
    React.useEffect(() => {
      // Fetch user's Stripe ID from Firestore
      const fetchStripeId = async () => {
        try {
          const user = firebase.auth().currentUser;
          if (!user) {
            throw new Error('User not authenticated');
          }
  
          const docRef = firebase.firestore().collection('customer').doc(user.uid);
          const docSnapshot = await docRef.get();
          if (docSnapshot.exists) {
            const userData = docSnapshot.data();
            const stripeId = userData.stripeId;
            // Fetch user's purchased products from Stripe using the retrieved Stripe ID
            fetchUserPurchasedProducts(stripeId);
            console.log(userPurchasedProducts);
          } else {
            throw new Error('User data not found in Firestore');
          }
        } catch (error) {
          console.error('Error fetching user Stripe ID:', error);
        }
      };
  
      fetchStripeId();
    }, []);

  return (
    <ThemeProvider theme={theme}> {/* Wrap your entire app with ThemeProvider */}
      <Router>
        <NavBar/>
        <Switch>
        <Route exact path="/Mock1/FLK1/Part1"><FLK1Mock1Part1/></Route>
        <Route exact path="/Mock1/FLK1/Part2"><FLK1Mock1Part2/></Route>
        <Route exact path="/Mock2/FLK1/Part1"><FLK1Mock2Part1/></Route>
        <Route exact path="/Mock2/FLK1/Part2"><FLK1Mock2Part2/></Route>
        <Route exact path="/Mock/FLK2/Part1"><FLK2MockPart1/></Route>
        <Route exact path="/Mock/FLK2/Part2"><FLK2MockPart2/></Route>

          <Route exact path="/"><Home /></Route>
          <Route exact path="/ErrorPage"><ErrorPage/></Route>
          <Route exact path="/PrivacyPolicy"><PrivacyPolicy/></Route>
          <Route exact path="/CookiesPolicy"><CookiesPolicy/></Route>
          <Route exact path="/SocialMediaPolicy"><SocialMediaPolicy/></Route>
          <Route exact path="/WebsiteTermsAndConditions"><WebsiteTermsAndConditions/></Route>
          <Route path="/Login"><Login/></Route>
          <Route path="/SignUp"><SignUp/></Route>

          {/* FLK1 Quizzes */}
          <Route path="/Quiz/FLK1/BusinessFinance"><BusinessFinanceQuiz/></Route>
          <Route path="/Quiz/FLK1/BusinessLaw"><BusinessLawQuiz/></Route>
          <Route path="/Quiz/FLK1/ConstitutionalLaw"><ConstitutionalLawQuiz/></Route>
          <Route  path="/Quiz/FLK1/ContractLaw"><ContractLawQuiz/></Route>
          <Route path="/Quiz/FLK1/DisputeResolution"><DisputeResolutionQuiz/></Route>
          <Route path="/Quiz/FLK1/Ethics"><EthicsQuiz/></Route>
          <Route path="/Quiz/FLK1/HumanRightsAct"><HumanRightsActQuiz/></Route>
          <Route path="/Quiz/FLK1/LegalService"><LegalServiceQuiz/></Route>
          <Route path="/Quiz/FLK1/LegalSystems"><LegalSystemsQuiz/></Route>
          <Route path="/Quiz/FLK1/RetainedEULaw"><RetainedEULawQuiz/></Route>
          <Route path="/Quiz/FLK1/TortLawQuiz"><TortLawQuiz/></Route>
          {/* FLK2 Quizzes */}
          <Route path="/Quiz/FLK2/CriminalLaw"><CriminalLawQuiz/></Route>
          <Route path="/Quiz/FLK2/CriminalPractice"><CriminalPracticeQuiz/></Route>
          <Route path="/Quiz/FLK2/PropertyPractice"><PropertyPracticeQuiz/></Route>
          <Route path="/Quiz/FLK2/LandLaw"><LandLawQuiz/></Route>
          <Route path="/Quiz/FLK2/Trusts"><TrustsQuiz/></Route>
          <Route path="/Quiz/FLK2/SolicitorsAccounts"><SolicitorsAccountsQuiz/></Route>
          <Route path="/Quiz/FLK2/WillsAndAdministrationOfEstates"><WillsAndAdministrationOfEstatesQuiz/></Route>
          <Route path="/Quiz/FLK2/InheritanceTax"><InheritanceTaxQuiz/></Route>
          {/* LLB Quizzes */}


          { user ?
          <Route path="/Dashboard" component={Dashboard} user={user}><Dashboard/></Route>
          :
          <Route exact path="/Dashboard"><Home /></Route>
}
{ user ?
          <Route path="/MySubscription" component={MySubscription} user={user}><MySubscription/></Route>
          :
          <Route exact path="/MySubscription"><Home /></Route>
}
          {/*<Route path="/Quiz"><Quiz/></Route>*/}
          <Route path="/FLK1"><FLK1/></Route>
          <Route path="/FLK2"><FLK2/></Route>
          {/* <Route path="/LLB"><LLB/></Route> */}
          {/*<Route path="/Articles"><Articles/></Route>*/}
          <Route path="/FAQ"><FAQ/></Route> 

          {/* FLK1 Learning Material */}
          { planType === 'Gold' || planType === 'Silver' || planType === 'Bronze Revision' ?
          <Switch>
          <Route path="/LearningMaterial/FLK1/BusinessFinance"><BusinessFinance/></Route> 
          <Route path="/LearningMaterial/FLK1/BusinessLaw"><BusinessLaw/></Route> 
          <Route path="/LearningMaterial/FLK1/ConstitutionalLaw"><ConstitutionalLaw/></Route> 
          <Route path="/LearningMaterial/FLK1/DisputeResolution"><DisputeResolution/></Route> 
          <Route path="/LearningMaterial/FLK1/ContractLaw"><ContractLaw/></Route> 
          <Route path="/LearningMaterial/FLK1/Ethics"><Ethics/></Route> 
          <Route path="/LearningMaterial/FLK1/LegalService"><LegalService/></Route> 
          <Route path="/LearningMaterial/FLK1/LegalSystems"><LegalSystems/></Route>
          <Route path="/LearningMaterial/FLK1/HumanRightsAct"><HumanRightsAct/></Route>
          <Route path="/LearningMaterial/FLK1/RetainedEULaw"><RetainedEULaw/></Route>
          <Route path="/LearningMaterial/FLK1/TortLaw"><TortLaw/></Route> 

          <Route path="/LearningMaterial/FLK2/CriminalPractice"><CriminalPractice/></Route> 
    <Route path="/LearningMaterial/FLK2/InheritanceTax"><InheritanceTax/></Route> 
    <Route path="/LearningMaterial/FLK2/LandLaw"><LandLaw/></Route> 
    <Route path="/LearningMaterial/FLK2/PropertyPractice"><PropertyPractice/></Route> 
    <Route path="/LearningMaterial/FLK2/CriminalLaw"><CriminalLaw/></Route> 
    <Route path="/LearningMaterial/FLK2/SolicitorAccounts"><SolicitorAccounts/></Route> 
    <Route path="/LearningMaterial/FLK2/Trusts"><Trusts/></Route> 
    <Route path="/LearningMaterial/FLK2/WillsAndAdministrationOfEstates"><WillsAndAdministrationOfEstates/></Route> 
          </Switch> 
          : 
          planType === 'Free' ?
          <Switch>
          <Route path="/LearningMaterial/FLK1/BF846"><BusinessFinance/></Route> 
          <Route path="/LearningMaterial/FLK1/BL357"><BusinessLaw/></Route> 
          <Route path="/LearningMaterial/FLK1/CL902"><ConstitutionalLaw/></Route> 
          <Route path="/LearningMaterial/FLK1/CL619"><ContractLaw/></Route> 
          <Route path="/LearningMaterial/FLK1/ET124"><Ethics/></Route> 
          <Route path="/LearningMaterial/FLK1/LS753"><LegalService/></Route> 
          <Route path="/LearningMaterial/FLK1/LS481"><LegalSystems/></Route> 
          <Route path="/LearningMaterial/FLK1/TL236"><TortLaw/></Route> 

    <Route path="/LearningMaterial/FLK2/CP352"><CriminalPractice/></Route> 
    <Route path="/LearningMaterial/FLK2/IT861"><InheritanceTax/></Route> 
    <Route path="/LearningMaterial/FLK2/LL574"><LandLaw/></Route> 
    <Route path="/LearningMaterial/FLK2/CL202"><CriminalLaw/></Route> 
    <Route path="/LearningMaterial/FLK2/SA435"><SolicitorAccounts/></Route> 
    <Route path="/LearningMaterial/FLK2/TT792"><Trusts/></Route> 
    <Route path="/LearningMaterial/FLK2/WAEOE369"><WillsAndAdministrationOfEstates/></Route> 

            </Switch>
            :
            <Switch>
              </Switch>
}
{/* FLK2 Learning Material */}
{ planType === 'Gold' || planType === 'Silver' || planType === 'Bronze Revision' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/CriminalPractice"><CriminalPractice/></Route> 
    <Route path="/LearningMaterial/FLK2/InheritanceTax"><InheritanceTax/></Route> 
    <Route path="/LearningMaterial/FLK2/LandLaw"><LandLaw/></Route> 
    <Route path="/LearningMaterial/FLK2/CriminalLaw"><CriminalLaw/></Route> 
    <Route path="/LearningMaterial/FLK2/SolicitorAccounts"><SolicitorAccounts/></Route> 
    <Route path="/LearningMaterial/FLK2/Trusts"><Trusts/></Route> 
    <Route path="/LearningMaterial/FLK2/WillsAndAdministrationOfEstates"><WillsAndAdministrationOfEstates/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/CP352"><CriminalPractice/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/IT861"><InheritanceTax/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/LL574"><LandLaw/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/CL202"><CriminalLaw/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/SA435"><SolicitorAccounts/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/TT792"><Trusts/></Route> 
  </Switch> : 
  planType === 'Free' ?
  <Switch>
    <Route path="/LearningMaterial/FLK2/WAEOE369"><WillsAndAdministrationOfEstates/></Route> 
  </Switch> : 
  <Switch>
  </Switch>
}

        </Switch>
        <Footer/>
      </Router>
    </ThemeProvider>
  );

}

export default App;
