import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid  from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";

import Contract_Law from './img/Contract_Law.jpg';
import Tort_Law from './img/Tort_Law.jpg';
import Business_Law from './img/Business_Law.jpg';
import Constitutional_Law from './img/Constitutional_Law.jpg';
import Retained_EU_Law from './img/Retained_EU_Law.jpg';
import Dispute_Resolution from './img/Dispute_Resolution.jpg';
import Legal_Service from './img/Legal_Service.jpg';
import Legal_Systems from './img/Legal_Systems.jpg';
import Ethics from './img/Ethics.jpg';
import Human_Rights_Act from './img/Human_Rights_Act.jpg';
import Business_Finance from './img/Business_Finance.jpg';


// const baseUrl = process.env.PUBLIC_URL;


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: '100%',
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: '100%',
  },
  "&:hover, &.Mui-focusVisible": {
    "& .MuiImageBackdrop-root": {
      opacity: 0.9,
      backgroundColor: "#004AAD",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "4px",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  borderRadius: "4px",
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.45,
  transition: theme.transitions.create("opacity"),
  borderRadius: "4px",
}));


export default function FLK1({open, onClose}) {

  return (
    <div style={{}}>

      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#004AAD" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              FLK1 Topics Quiz 
            </Typography>

            <IconButton
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

<br/>

<Grid container spacing={2} sx={{width:'96%', marginLeft:'1%', marginRight:'1%'}}>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>
        <ImageButton
          focusRipple
          key={'CONTRACT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/ContractLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Contract_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CONTRACT LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>
        <ImageButton
          focusRipple
          key={'TORT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/TortLawQuiz/";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Tort_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
            TORT LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'BUSINESS LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/BusinessLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Business_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      BUSINESS LAW
            </Image>
          </ImageButton>
        </Grid>

        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'CONSTITUTIONAL LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/ConstitutionalLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Constitutional_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CONSTITUTIONAL LAW
            </Image>
          </ImageButton>

        </Grid>

        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'RETAINED EU LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/RetainedEULaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Retained_EU_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      RETAINED EU LAW
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'DISPUTE RESOLUTION'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/DisputeResolution";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Dispute_Resolution })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      DISPUTE RESOLUTION
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'LEGAL SERVICE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/LegalService";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Legal_Service})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LEGAL SERVICE
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'LEGAL SYSTEMS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/LegalService";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Legal_Systems })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LEGAL SYSTEMS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'ETHICS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/Ethics";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Ethics })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      ETHICS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'HUMAN RIGHTS ACT'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/HumanRightsAct";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Human_Rights_Act })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      HUMAN RIGHTS ACT
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'BUSINESS FINANCE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/BusinessFinance";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Business_Finance })`,
          borderRadius: "4px",
          filter: 'grayscale(100%)',
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
        BUSINESS FINANCE
      {/*BUSINESS FINANCE*/}
            </Image>
          </ImageButton>

        </Grid>
      </Grid>

        <br/>
      </Dialog>

<br/>

    </div>
  );
}
