import React, { useState, useEffect } from 'react';
import { Container, Tabs, Tab, Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";

import firebase from './firebase'; 
import 'firebase/firestore';

function ExamTabs({ open, onClose }) {
  const [value, setValue] = useState(0);
  const [partOneResults, setPartOneResults] = useState([]);
  const [partTwoResults, setPartTwoResults] = useState([]);

  const [partOneAvailable, setOneAvailability] = useState('');
  const [partTwoAvailable, setTwoAvailability] = useState('');
  const [twoPartOneAvailable, setThreeAvailability] = useState('');
  const [twoPartTwoAvailable, setFourAvailability] = useState('');
  const [isGoldPlan, setIsGoldPlan] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      const partOneRef = firebase.database().ref(`users/${user.uid}/flk1Mock/partOneResults`);
      partOneRef.on('value', (snapshot) => {
        const data = snapshot.val();
        const results = data ? Object.values(data) : [];
        setPartOneResults(results);
      });

      const partTwoRef = firebase.database().ref(`users/${user.uid}/flk1Mock/partTwoResults`);
      partTwoRef.on('value', (snapshot) => {
        const data = snapshot.val();
        const results = data ? Object.values(data) : [];
        setPartTwoResults(results);
      });

      const userRef = firebase.database().ref(`users/${user.uid}`);
      userRef.on('value', (snapshot) => {
        const userVal = snapshot.val();
        if (userVal) {
          setOneAvailability(userVal.flk1Mock.partOneAvailable);
          setTwoAvailability(userVal.flk1Mock.partTwoAvailable);
          setThreeAvailability(userVal.flk1Mock.twoPartOneAvailable);
          setFourAvailability(userVal.flk1Mock.twoPartTwoAvailable);
          setIsGoldPlan(userVal.subscription && userVal.subscription.planType === "Gold");
        }
      });
    }
  }, []);

  const renderTabContent = (availability, results, examLink, purchaseLink) => {
    if (isGoldPlan || availability === 'yes') {
      return (
        <>
          <Button variant="contained" color="primary" component={Link} to={examLink} sx={{ mb: 2, width: '100%' }}>
            Start Exam
          </Button>
          <Typography sx={{ mb: 1, textAlign: 'center', alignItems: 'center' }} variant="h6" component="div">
            Previous Attempts
          </Typography>
          <ResultsTable results={results} />
        </>
      );
    } else {
      return (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Purchase this exam
            </Typography>
            <Button variant="contained" color="primary" href={purchaseLink}>
              Purchase the exam here
            </Button>
          </CardContent>
        </Card>
      );
    }
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar sx={{ position: "relative", backgroundColor: "#004AAD" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            FLK1 Mock
          </Typography>

          <IconButton color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <br />
      <Container maxWidth="md" sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          FLK1 Mock Exam
        </Typography>
        <Typography variant="body2">
          Each part is 180 minutes & 90 questions long.
          <br />
          Once you start one part, you cannot pause the exam.
          <br />
          Good Luck!
        </Typography>
      </Container>

      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Box sx={{ width: '100%' }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="MOCK 1 PART 1" sx={{ width: '25%' }} />
            <Tab label="MOCK 1 PART 2" sx={{ width: '25%' }} />
            <Tab label="MOCK 2 PART 1" sx={{ width: '25%' }} />
            <Tab label="MOCK 2 PART 2" sx={{ width: '25%' }} />
          </Tabs>
          {value === 0 && (
            <TabPanel value={value} index={0}>
              {renderTabContent(partOneAvailable, partOneResults, "/Mock1/FLK1/Part1", "https://buy.stripe.com/5kAeVra771AEaB228r")}
            </TabPanel>
          )}
          {value === 1 && (
            <TabPanel value={value} index={1}>
              {renderTabContent(partTwoAvailable, partTwoResults, "/Mock1/FLK1/Part2", "https://buy.stripe.com/28o00xa777Z29wY00l")}
            </TabPanel>
          )}
          {value === 2 && (
            <TabPanel value={value} index={2}>
              {renderTabContent(twoPartOneAvailable, partOneResults, "/Mock2/FLK1/Part1", "https://buy.stripe.com/8wM8x3bbbenq6kM00m")}
            </TabPanel>
          )}
          {value === 3 && (
            <TabPanel value={value} index={3}>
              {renderTabContent(twoPartTwoAvailable, partTwoResults, "/Mock2/FLK1/Part2", "https://buy.stripe.com/8wM3cJ9333IMcJa7sP")}
            </TabPanel>
          )}
        </Box>
        {!isGoldPlan && (
          <Button variant="contained" color="primary" component={Link} to="/Mock/FLK1/Part2" sx={{ mb: 2, width: '100%', height: '29%' }}>
            Buy All 4 Exams for £25
          </Button>
        )}
      </Container>
    </Dialog>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ResultsTable({ results }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Score</TableCell>
            <TableCell align="right">Topic Results</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((result, index) => (
            <TableRow key={index}>
              <TableCell align="right">{result.score}</TableCell>
              <TableCell align="right">
                {result.calculatedTagScores &&
                  Object.entries(result.calculatedTagScores).map(([tag, { correct, total }]) => {
                    const percentage = ((correct / total) * 100).toFixed(0);
                    return (
                      <div key={tag}>
                        {tag}: {percentage}%
                      </div>
                    );
                  })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ExamTabs;
