import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PDF from "./HumanRightsAct.pdf";
import "../../App.css";
import { IconButton, Tooltip } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const HumanRightsAct = () => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setPageWidth(0.6 * window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <div className="text-center">
            <div className="controls">
                <Tooltip title="Previous">
                    <IconButton size='large' onClick={prevPage} disabled={pageNumber === 1}>
                        <NavigateBefore size='large'/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Next">
                    <IconButton size='large' onClick={nextPage} disabled={pageNumber === numPages}>
                        <NavigateNext size='large'/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="pdf-container" style={{ display: 'flex', justifyContent: 'center', userSelect: 'none' }}>
                <Document
                    file={PDF}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="pdf-document"
                    renderAnnotationLayer={false}
                >
                    <Page pageNumber={pageNumber} width={pageWidth}/>
                </Document>
            </div>
            <div className="controls">
                <Tooltip title="Previous">
                    <IconButton size='large' onClick={prevPage} disabled={pageNumber === 1}>
                        <NavigateBefore size='large'/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Next">
                    <IconButton size='large' onClick={nextPage} disabled={pageNumber === numPages}>
                        <NavigateNext size='large'/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

export default HumanRightsAct;
