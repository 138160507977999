import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Card, CardContent, } from '@mui/material';
import firebase from './firebase'; 


  function Subscription(){

   const [userId, setUserId] = useState('');
   const [planType, setPlanType] = useState('');
  
    useEffect(()=>{
      firebase.auth().onAuthStateChanged((user)=>{
        if(user){
          setUserId(user.uid);
          const userRef = firebase.database().ref("users/"+user.uid);
          userRef.on("value", (snapshot)=>{
            const userVal = snapshot.val();
            if(userVal){
              setPlanType(userVal.subscription.planType || "")
            }
          });
        }else{
          setUserId('');
        }
      })
  }, [userId]);

  const checkout = (plan)=>{
    fetch("https://lawschoolcompass.com/api/v1/create-subscription-checkout-session",
    {
      method:'POST',
      headers:{
        'content-Type':'application/json'
      },
      mode:'cors',
      body:JSON.stringify({plan: plan, customerId: userId})
    })
      .then((res)=>{
        if(res.ok) return res.json();
        console.log(res);
        const jsonDatas = res.json().then((json)=> Promise.reject(json));
        console.log(jsonDatas);
        //return res.json().then((json)=> Promise.reject(json));
        return jsonDatas
      }
      ).then(({session})=>{
        //console.log('session:' + session)
        console.log('session.url: ' + session.url);
        window.open(session.url);
      })
      .catch((error)=>{
        //console.log(session);
        console.log(JSON.stringify({plan: plan, customerId: userId}))
        console.error("There's an error: " + error.message)
      })
    }

    const subscriptionOptions = [
      {
        title: 'Gold Account',
        planType : 'Gold',
        price: '£45/month',
        subprice:'45',
        url:'',
        features: [
        
          'Access to all learning material and lectures',
          'Access to Multiple Choice Quizzes (Configurable up to 60 questions)',
          'Access to Online Mock Exams',
        ],
        color: "linear-gradient(to right, #E0AA3E, #F2DE7A, #E0AA3E, #E0AA3E)",
        textColor: "black",
      },
      {
        title: 'Silver Account',
        planType : 'Silver',
        price: '£35/month',
        subprice:'35',
        url:'',
        features: [
          
          'Access to all learning material and lectures',
          'Access to Multiple Choice Quizzes (Configurable up to 45 questions)',
        ],
        color: "linear-gradient(to right, #B4B4B4, #FFFFFF, #B4B4B4, #B4B4B4)",
        textColor: "black",
      },
      {
        title: 'Bronze Account',
        planType : 'Bronze',
        price: '£25/month',
        subprice:'25',
        url:'',
        features: [
          
          'Access to all learning material and lectures',
        ],
        color: "linear-gradient(to right, #CF603D, #F2BA7A, #CF603D, #CF603D)",
        textColor: "black",
      },
      {
        title: 'Free Account',
        planType : "Free",
        price: 'Free',
        subprice:'0',
        url:'',
        features: [
          
          'One-time purchases on learning material (yours to keep while subscribed!)',
        ],
        color: "linear-gradient(to right, #004AAD, #0064EA, #004AAD, #004AAD )",
        textColor: "white",
      }
    ];

    const pricingOptions = [
  {
    name: "Free Account",
    price: "£0/month",
    description: "One-time purchases on learning material and mock exams (yours to keep while subscribed!)",
    stripeLink: "https://buy.stripe.com/28o8x3a77a7agZq3cu",
  },
  {
    name: "Bronze Account - Revision",
    price: "£25/month",
    description: "Access to all learning material and one-time purchases on mock exams",
    stripeLink: "https://buy.stripe.com/eVa8x3gvvdjmfVm7sH",
  },
  {
    name: "Bronze Account - Practise",
    price: "£25/month",
    description: "Access to all multiple-choice quizzes and one-time purchases on mock exams",
    stripeLink: "https://buy.stripe.com/9AQdRn2EF4MQ4cE9B0",
  },
  {
    name: "Silver Account",
    price: "£35/month",
    description: "Access to all multiple-choice quizzes and one-time purchases on mock exams",
    stripeLink: "https://buy.stripe.com/8wMdRnbbb9369wY8wM",
  },
  {
    name: "Gold Account",
    price: "£45/month",
    description: "Access to all multiple-choice quizzes and mock exams",
    stripeLink: "https://buy.stripe.com/aEU4gNa77a7agZqbIZ",
  }
];

    const [selectedOption, setSelectedOption] = React.useState('');

    const handleOptionChange = (option) => {
      setSelectedOption(option.title === selectedOption ? '' : option.title);
    };

    return (
      <Box sx={{ justifyContent: 'center', }}>
              <Typography variant="h4" align="center" gutterBottom>
        Subscription
      </Typography>
      <Box mb={2} textAlign="center">
          {/*
          <Typography variant="body2">
            You currently have a {planType} Account
          </Typography>
          */}
          <a href='https://billing.stripe.com/p/login/4gw181dNQ2cW0CIfYY'>
            Organise Your Account Here
            </a>
        </Box>
        <br/>
        <Grid container spacing={0} sx={{display:'none'}}>
          {subscriptionOptions.map((option, index) => (
            
            <Grid item xs={12} sm={6}key={index} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom:'2%'}}>
              
              <Card
                elevation={4}
                
                style={{ 
                  width:'95%',
                  background: option.color,
                  color: option.textColor ,
                  fontWeight:'normal',
                  padding: 10,
                  height: '100%',
                  animation: 'shimmer 600s linear infinite both',
                  
                }}
              >
                <CardContent>
                <Typography variant="h5" gutterBottom style={{fontWeight: selectedOption === option.title ? 'bold' : 'normal',}}>
                  {option.title}
                </Typography>
                <Typography variant="body1" style={{fontWeight: selectedOption === option.title ? 'bold' : 'normal',}}>
                  {option.price}
                </Typography>
                <ul>
                  {option.features.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
                </CardContent>
                { planType === option.planType ?
                                <Button disabled
                                style={{backgroundColor:'rgba(255,255,255,0.75)',display:'block',color:'black', fontWeight:'bold', size:'large', marginLeft:'2%'}}>
                                Subscribed😎
                                </Button>
                                : 
                                <div style={{display:'none'}}>
                  {/* <Button 
                  onClick={() => { checkout(Number(option.subprice))}}
                style={{backgroundColor:'rgba(255,255,255,0.75)',display:'block',color:'black', fontWeight:'bold', size:'large', marginLeft:'2%'}}>
                Subscribe
                </Button> */}
                </div>
  }
        </Card>
              
              
            </Grid>
            
                    ))}
        </Grid>
        <stripe-pricing-table pricing-table-id="prctbl_1PqhCVGSMYUEf8qr8bctP9qd"
publishable-key="pk_live_51Oxu7lGSMYUEf8qrT2pz2ouCFUERNVdx9FPsLMqByaBHEEKZ9ks9fxgGhxIEFybGLAH2IJsuTXzYXObTFg4y3NuB00fb7kNAJ2">
</stripe-pricing-table>
<stripe-pricing-table pricing-table-id="prctbl_1PDkV7GSMYUEf8qrs4fUh1sM"
publishable-key="pk_live_51Oxu7lGSMYUEf8qrT2pz2ouCFUERNVdx9FPsLMqByaBHEEKZ9ks9fxgGhxIEFybGLAH2IJsuTXzYXObTFg4y3NuB00fb7kNAJ2">
</stripe-pricing-table>

      </Box>
                    );
};

export default Subscription;
