import React from 'react';
import { Typography, Container } from '@mui/material';

const WebsiteTermsAndConditions = () => {
  return (
    <Container className='text-center' style={{marginLeft:'4%', marginRight:'4%'}}>
      <Typography variant="h4" gutterBottom>
      Website Terms and Conditions 2024   
      </Typography>
      <Typography variant="body1" paragraph>
      <strong>IMPORTANT: We recommend that you print a copy of these terms for future reference.</strong>
      </Typography>
      <Typography variant="body1" paragraph>
      <strong>
Introduction</strong>
<br />
1.1. Please carefully read these website Terms of Use (“Agreement”). This Agreement has been prepared as a legally binding agreement between the user (“you” or “your”) and LAW SCHOOL COMPASS LIMITED together with any subsidiaries and affiliates, (“LAW SCHOOL COMPASS LIMITED”, “we”, “our” or “us’ for the website www.lawschoolcompass.com  (the “site”).
<br />
1.2. These terms and conditions govern the way in which we supply products to you, including but not limited or restricted to any supplementary eLearning revision material and any tuition services. Your use of our site and engagement in our services is only on the condition that you agree and abide by the terms in this agreement. By engaging with this site and our products/ services you are bound by this agreement. Please do not access this site or our content in any way if you do not agree to these terms of use.
<br />
1.3. Your review of these terms and conditions is essential before completing your order. These terms establish the governing framework for our relationship, including details regarding our identity, product provision protocols, contractual adjustments and termination protocols, conflict resolution procedures, and additional relevant provisions.
<br />
1.4. By completing an order and engaging in our services, you are agreeing to review this agreement periodically in order to ensure that you are aware of any amendments to this agreement, which may be made at any time without notice.
<br />
1.5. By using our website, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our website.

</Typography>
<Typography variant="body1" paragraph> 
<strong>Who we are and how to contact us</strong> 
<br />
2.1. We are registered in England and Wales under the Companies Act 2006. We are a limited company. 
<br />
2.2. Company number: 15528669
<br />
2.3. We are a publisher of supplementary eLearning revision material operating in the United Kingdom. 
<br />
2.4. We are not currently sponsored or endorsed by any college or university. 
<br />
2.5. It is important to understand that using our site constitutes your acceptance of and compliance with these terms of use. 
<br />
2.6. If you do not agree to these terms, you must not use our sites. 
<br />
2.7. These terms of use refer to our Privacy and Cookie Policy  which also apply to your use of our website. You can find this on our website 
<br />
2.8. Some features of the Site may allow you to provide content to the Site, such as written comments. This content is shared publicly on the Site. Therefore, all content submitted by you to the Site may be retained by us for as long as we require it in relation to the purposes set out in the Privacy Policy, which can be viewed on our website, subject to state law, even after you terminate use. We may continue to disclose such content to third parties, as described in the Privacy Policy.
<br />
2.9. You can contact us via email at: info.admin@lawschoolcompass.com. 
<br />
2.10. Your contract with us begins upon payment of a subscription to us..This signifies our acceptance of your order and the beginning of your contract with us.
<br />
2.11. In exchange for your payment, we are giving you a licence to use the eLearning revision materials. However, this licence is limited, meaning it can be revoked and is not exclusive, sublicensable, or transferable. You will find more information about your rights and obligations in our Terms. This licence is personal to you and cannot be shared, sold,  or exchanged with others.
</Typography><Typography variant="body1" paragraph> 
<strong>Our responsibilities to one another.</strong>
<br />
3.1. Our responsibilities: 
<br />
3.1.1. We will create, manage, and update the eLearning revision materials (“Products”).
<br />
3.1..2. We will provide you with login details for access.
<br />
3.1.3. We will manage your access and assist you with technical support when needed upon notice.
<br/>
<strong>3.2. Your responsibilities </strong>
<br />
3.2.1. You will use the Products solely for your individual training.
<br />
3.2.2. You will not copying, modifying, distributing, or exploiting the Products or any other copyrighted materials in any way.
<br />
3.2.3.  You will prevent others from engaging in these prohibited actions as well.
<br />
3.2.4. You will not use LAW SCHOOL COMPASS LIMITED’S name, server or other materials in connection with, or to transmit any unsolicited communications or emails. 
<br />
3.2.5. You will not introduce any viruses, Trojan horses, worms, logic bombs or other materials which are malicious or technologically harmful.
<strong>
IMPORTANT disclaimer </strong>
<br />
4.1. The Products are provided "as is," without express or implied warranties (except those that cannot be excluded legally).
<br />
4.2. We cannot guarantee the Products will be error-free or technically perfect.
</Typography>
<Typography variant="body1" paragraph>
<strong>Access to Materials</strong>
<br />
5.1. You must keep your account details safe. 
<br />
5.2. Your identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.
<br />
5.3. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.
<br />
5.4.If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at info.admin@lawschoolcompass.com. 
<br />
5.5. Your date of purchase is deemed to be the starting date of your access to the Product(s).
<br />
5.6. We will do our best to ensure you have uninterrupted access to the materials, but sometimes things beyond our control may cause interruptions. This could include unexpected events such as power outages, hacking, acts of God, or software issues. We might also need to interrupt access for necessary reasons such as server maintenance or high traffic. If any of the aforementioned events,, we will attempt to restore service to the Product(s) as soon as we reasonably can. "Reasonably" in this case means we will make good-faith efforts without spending any unreasonable amount of time, resources, or money.
<br />
5.7. Our unwavering commitment is to provide supplementary revision courses and materials of the highest calibre. To this end, we may, at our sole discretion, implement improvements or modifications to the Products or any other associated materials at any time. This proactive approach ensures their continued relevance and accuracy.
<br />
5.8. In the event that your access to the Products is temporarily restricted due to any of the aforementioned circumstances, we may elect to extend your access period at no additional cost. This decision will be made based on our sole discretion.
<br />
5.9. Your access to this website is provided on a temporary basis with no guarantee for future availability. We reserve the right to withdraw or modify any content or products we provide on this site without notice.
</Typography><Typography variant="body1" paragraph> 
<strong>Pricing & Payment</strong>
<br />
6.1. We use third party payment providers, depending on the way in which you make payments. Payment for the Products must be made at the point of purchase.
<br />
6.2. You are obligated to make payment for the Products in the specified currency. You will be responsible for any applicable conversion charges, associated fees, and sales tax in your region. It is important to note that full payment must be received before you can gain access to the Products.
<br />
6.3. Your payment grants you a licence to use the Products for a limited period of time, aligned with the duration of the eLearning revision content. Refunds are not typically issued for uncompleted content, except in rare cases at our absolute discretion.
<br />
6.4.We reserve the right to adjust the pricing of any Product at our discretion. However, such changes will not affect Products that have already been purchased. From time to time, we may offer discount codes or promotions on our website. If you were unable to use a valid discount code at the time of purchase, you may request a refund of the discount amount. Please contact our support team for more information regarding this process.
<strong>
  Disclaimer </strong>
  <br />
7.1. We use third party payment providers, depending on the way in which you make payments. Payment for the Products must be made at the point of purchase.
<br />
7.2. You are obligated to make payment for the Products in the specified currency. You will be responsible for any applicable conversion charges, associated fees, and sales tax in your region. It is important to note that full payment must be received before you can gain access to the Products.
<br />
7.3. Your payment grants you a licence to use the Products for a limited period of time, aligned with the duration of the eLearning revision content. Refunds are not typically issued for uncompleted content, except in rare cases at our absolute discretion.
<br />
7.4.We reserve the right to adjust the pricing of any Product at our discretion. However, such changes will not affect Products that have already been purchased. From time to time, we may offer discount codes or promotions on our website. If you were unable to use a valid discount code at the time of purchase, you may request a refund of the discount amount. Please contact our support team for more information regarding this process.

</Typography>
<Typography variant="body1" paragraph>
<strong>Cancellation Policy </strong>
<br />
8.1.  Under the Consumer Contracts (Information, Cancellation and Additional Charge) Regulations 2013 (“Consumer Contracts Regulations”) you may cancel any of your purchases within 14 calendar days from the date you purchased your goods. 
<br />
8.2. You must inform Law School Compass of your decision to cancel by email: info.admin@lawschoolcompass.com
<br />
8.3. If you cancel outside of the Cancellation Period refunds will not be issued.
<br />
8.4. If you are on a repeated subscription with us, you can cancel this subscription by emailing us (info.admin@lawschoolcompass.com). Please note, you must give us at least 30 days notice that you wish to cancel. 
</Typography><Typography variant="body1" paragraph>
<strong>Loss or damages </strong>
<br />
9.1. Foreseeable Loss and Damage: We are not responsible for any foreseeable loss or damage that you suffer as a direct result of our breach of these terms or your failure to use reasonable care and skill. This includes situations where you have discussed the potential for such loss or damage with us during the sales process.
<br />
9.2. Indirect or Consequential Damages: We are not liable for any indirect, special, incidental, punitive, or consequential damages arising from your use of the Products, even if we are aware of the possibility of such damages. This includes, but is not limited to, lost goodwill, work stoppages, computer failures, data loss, loss of productivity, and contractual or commercial losses.
<br />
9.3. Limited Exclusions: We do not exclude or limit our liability in any way where it would be unlawful to do so. This includes liability for:
<br />
9.4. Death or personal injury caused by our negligence or the negligence of our employees, agents, or subcontractors, fraud or fraudulent misrepresentation, Breach of your legal rights in relation to the Products.
<br />
9.5. Damage to your device or digital content: If the Products damage your device or digital content due to our failure to use reasonable care and skill, we will either repair the damage or compensate you for the loss. However, we are not liable for any damage that you could have avoided by following our instructions, adhering to installation/download guidelines, or meeting the minimum system requirements.
<br />
9.6. Limitation of Liability for Downloads: Our liability for any damage to your computer system or data loss resulting from downloading content is limited to the amount you paid for the Products. We will not be liable for any damages exceeding this amount.
</Typography><Typography variant="body1" paragraph>
<strong>Viruses, Hacking & Other Offences</strong>
<br />
10.1. Protecting the integrity if the website and product by safeguarding against harmful conduct: You are strictly prohibited from uploading, posting, distributing, or publishing any files on the Website or Product that contain viruses, corrupted data, malicious code, or any other software that could potentially damage or impair the operation of another user's computer.
<br />
10.2. Prohibiting misuse and unauthorised access: You must not intentionally introduce any harmful content, including viruses, trojans, worms, logic bombs, or other malicious or technologically harmful material, into the Website or Product.
<br />
10.3. You must not attempt to gain unauthorised access to the Website, the Product, the servers that host them, or any connected computer systems or databases.
<br />
10.4. You must not engage in denial-of-service attacks or distributed denial-of-service attacks against the Website or Product.
<br />
10.5. Consequences of violation: Any breach of these terms constitutes a criminal offence under the Computer Misuse Act 1990. We will report such breaches to law enforcement authorities and fully cooperate by disclosing your identity. If you violate these terms, your membership and access to the Product will be terminated immediately.
</Typography><Typography variant="body1" paragraph>
<strong>Limitation of Liability </strong>
<br />
11.1. We are not responsible for any loss or damage caused by viruses, denial-of-service attacks, or other harmful content that may infect your computer equipment, software, data, or other property due to your use of the Product, your downloading of any material from it, or your access to linked websites.
<br />
11.2. Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.We have no control over the contents of those sites or resources, which may also be subject to change.
<br />
11.3. Please note the exclusions of limitation of liability contained in this document do not in any way, shape or form apply to any party’s liability with regards to any of the below: 
<br />
11.3.1.For Fraud 
<br />
11.3.2. For death or personal injury caused by negligence 
<br />
11.3.3. Where such limitation or exclusion cannot be lawfully excluded.
<br />
11.4. Except as set out in these terms, Law School Compass will not be responsible for any losses which result for failure to comply with these terms, including but not limited to losses which fall into any of the below categories: 
<br />
11.4.1. Loss of business 
<br />
11.4.2.Loss of revenue and income (including failed exams or loss of employment opportunities). 
<br />
11.4.3.Loss of anticipated savings.
<br />
11.4.4. Loss of corruption of data
</Typography>
<Typography variant="body1" paragraph>
<strong>Other terms </strong>
<br />
12.1. This agreement is between you and us, and we may transfer our responsibilities to another party, keeping you informed and ensuring your rights remain protected. While you cannot transfer your rights without our consent due to the personalised nature of the licence, no third party can enforce this agreement. If any part of the agreement becomes invalid, the rest remains in force. Our patience in enforcing your obligations does not negate our right to do so later, and your repeated non-compliance will not prevent future action. This agreement stands complete and enforceable under these terms.
<br />
12.2. Nobody else has any rights under these terms – they are between you and us. No other person shall have any rights to enforce any of its terms and the Contracts (Rights of Third Parties) Act 1999 is hereby disapplied.
</Typography><Typography variant="body1" paragraph>
<strong>Governing Law </strong>
<br />
13.1. These terms are governed by and interpreted in accordance with the laws of England and Wales.
<br />
13.2.  You agree to the exclusive jurisdiction of the English courts for any legal proceedings arising from or relating to these terms. However, if you reside in Scotland, you may initiate legal proceedings in either the Scottish or English courts. Similarly, if you reside in Northern Ireland, you may initiate legal proceedings for matters related to the Products in either the Northern Irish or English courts
</Typography><Typography variant="body1" paragraph>
<strong>General Legal Terms</strong>
<br />
14.1. It is your responsibility to check that the device you plan to use to access the Online Study materials is compatible with our services. We recommend that if you have any questions, you contact us before purchasing. Our email address is info.admin@lawschoolcompass.
<br />
14.2. You may not assign or subcontract any of your rights or obligations under these terms to any other person unless we agree in writing.
<br />
14.3. The Law School Compass must be notified of any queries, complaints or short or incorrect deliveries withinF14 days of you receiving your goods. If you do not notify us within this time-frame then we will not be obliged to remedy the situation.
</Typography>
<Typography variant="body1" paragraph>
<strong>Rules about redirection or linking to our site</strong>
<br />
15.1. You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.
<br />
15.2. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
<br />
15.3. You must not establish a link to our site in any website that is not owned by you.
<br />
14.4. Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.
<br />
15.4. We reserve the right to withdraw linking permission without notice.
<br />
15.5. If you wish to link to or make any use of content on our site other than that set out above, please contact 
</Typography>
<Typography variant="body1" paragraph>
Contact details
<br/>
Email Address: <a href="mailto:info.admin@lawschoolcompass.com">info.admin@lawschoolcompass.com </a>
<br/>
This policy was last updated in March 2024
      </Typography>
    </Container>
  );
};

export default WebsiteTermsAndConditions;
