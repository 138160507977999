import React from 'react';
import Typography from '@mui/material/Typography';

const PrivacyPolicy = () => {
  return (
    <div className='text-center' style={{marginLeft:'4%', marginRight:'4%'}}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Introduction</strong>
        <br />
        Thank you for visiting the Law School Compass’s privacy notice.
        At Law School Compass, safeguarding your personal data is of utmost importance to us. This privacy notice is designed to elucidate our approach to handling your personal data, referring to any information that can directly or indirectly identify you. The subsequent sections elaborate on how we utilize your data, the purposes for which it is used, and the parties with whom we share this information. Our commitment to transparency and data protection is in compliance with the EU General Data Protection Regulation (GDPR) and the Data Protection Act 2018. This privacy notice serves as a comprehensive guide on how Law School Compass manages and processes your personal data, encompassing information you may furnish while applying for any of our courses. Please review the details below attentively. We process data both within and outside the European Economic Area (EEA), adhering to all relevant data protection requirements.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Transfer of Third-Party Data</strong>
        <br />
        It is imperative to note that if you provide personal data concerning your parents, spouse, civil partner, relative, or any other third party, you assume responsibility for adhering to applicable data protection regulations. In specific instances, obtaining consent from the concerned individuals may be necessary for transferring their data, especially when providing contact details for emergency situations.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Data Controller</strong>
        <br />
        The entity responsible for controlling and processing your data is The Law School Compass, also referred to as "we" or "us" throughout this notice.
        If you have any inquiries or require clarification regarding the University's privacy policies, please reach out to our Data Protection Officer at info.admin@lawschoolcompass.com.
        We appreciate your commitment to understanding our privacy practices, and we are dedicated to ensuring the security and integrity of your personal data in accordance with established data protection laws.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Information we collect</strong>
        <br />
        <strong>Contact Information:</strong> Full name and email address.
        <br />
        <strong>Account Information:</strong> If you create an account, we may collect username, password, and other related details.
        <br />
        <strong>We automatically collect certain information when you visit our Site, including:</strong>
        <br />
        <strong>Device Information:</strong> IP address, browser type, device type, and other technical information.
        <br />
        <strong>Usage Information:</strong> Information about how you interact with our Site, pages visited, and features used.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>How We Collect Information</strong>
        <br />
        We use various methods to collect information:
        <br />
        <strong>Directly from You:</strong> When you provide information through forms, account creation, or other interactions on our Site.
        <br />
        <strong>Automatically:</strong> Through cookies, web beacons, and similar technologies as you navigate and interact with our Site.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Use of Information</strong>
        <br />
        We use the collected information for various purposes, including:
        <br />
        <strong>Providing Services:</strong> To deliver products, services, or information you request.
        <br />
        <strong>Communication:</strong> To respond to your inquiries, provide updates, and send important notices.
        <br />
        <strong>Customization:</strong> To personalize your experience on our Site.
        <br />
        <strong>Analytics:</strong> To understand how users interact with our Site, improve our services, and conduct research.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Your Choices</strong>
        <br />
        You have choices regarding your information:
        <br />
        <strong>Opt-Out:</strong> You can opt-out of receiving promotional communications.
        <br />
        <strong>Cookies:</strong> You can manage your cookie preferences through your browser settings.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Security Measures</strong>
        <br />
        We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Retention of Information</strong>
        <br />
        We retain your personal information for as long as reasonably necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Changes to this Privacy Policy</strong>
        <br />
        We may update this Privacy Policy periodically. The date of the latest revision will be indicated at the top. Significant changes will be communicated through appropriate channels.
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Contact Us</strong>
        <br />
        If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:info.admin@lawschoolcompasss.com">info.admin@lawschoolcompasss.com</a>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>Last updated:</strong> March 2024
      </Typography>
    </div>
  );
};

export default PrivacyPolicy;
