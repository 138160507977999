import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '50px' }}>
      <Alert severity="error">
        <h1>Error</h1>
        <h4>something went wrong</h4>
        </Alert>
      <Button component={Link} to="/" variant="contained" color="primary" style={{ marginTop: '20px' }}>
        Return Back to Home
      </Button>
    </Container> 
  );
};

export default ErrorPage;
