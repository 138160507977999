import React, { useState, useEffect } from 'react';
import { Modal, Paper, Typography, FormControl, FormGroup, FormControlLabel, Checkbox, TextField, Button, Snackbar, Container, Grid} from '@mui/material';
import './firebase'; //
import { styled } from '@mui/system';
import { getFirestore, addDoc, collection } from 'firebase/firestore';


const ContactForm = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [contactOptions, setContactOptions] = useState({
      tuition: false,
      mock: false,
      freeSample: false,
      workshops: false,
      signUpOptions: false,
    });

    const db = getFirestore();
    const saveDataToFirestore = async () => {
        const docRef = await addDoc(collection(db,"contactForm"), {
            email:email,
            firstName:firstName,
            contactOptions:contactOptions,
            timestamp: new Date(),
        });
        console.log(docRef);
    }
  
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleCheckboxChange = (event) => {
      setContactOptions({ ...contactOptions, [event.target.name]: event.target.checked });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();

    const formData = {
        email: email,
        firstName: firstName,
        contactOptions: contactOptions,
        timestamp: new Date(),
      };

      console.log('Form data:', formData);
        saveDataToFirestore()
        .then(()=>{
        setSnackbarMessage('Data successfully saved');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        console.log('Data successfully saved');
    })
    .catch((error) => {
        setSnackbarMessage('Error saving data');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('Error saving data: ', error);
      });
      // Reset form fields
      setEmail('');
      setFirstName('');
      setContactOptions({
        tuition: false,
        mock: false,
        freeSample: false,
        workshops: false,
        signUpOptions: false,
      });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
  
    return (
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
            <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} sx={{marginTop:"25%"}}>
            <Typography>
            What would you like us to contact you about
            </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={contactOptions.tuition} onChange={handleCheckboxChange} name="tuition" />}
              label="1-2-1 Tuition"
              sx={{textAlign: 'left'}}
            />
            <FormControlLabel
              control={<Checkbox checked={contactOptions.mock} onChange={handleCheckboxChange} name="mock" />}
              label="In-Person SQE Mock"
              sx={{textAlign: 'left'}}
            />
            <FormControlLabel
              control={<Checkbox checked={contactOptions.freeSample} onChange={handleCheckboxChange} name="freeSample" />}
              label="Free Sample Resources"
              sx={{textAlign: 'left'}}
            />
            <FormControlLabel
              control={<Checkbox checked={contactOptions.workshops} onChange={handleCheckboxChange} name="workshops" />}
              label="Workshops"
              sx={{textAlign: 'left'}}
            />
            <FormControlLabel
              control={<Checkbox checked={contactOptions.signUpOptions} onChange={handleCheckboxChange} name="signUpOptions" />}
              label="Sign Up Options"
              sx={{textAlign: 'left'}}
            />
          </FormGroup>
          </Grid>
          </Grid>
        <TextField
          label="Email"
          variant="standard"
          
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="First Name"
          variant='standard'
          
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        </FormControl>
        <br/>
        <Button onClick={handleSubmit} type="submit" variant="contained" color="primary" sx={{margin:"20px"}}>
          Submit
        </Button>
        
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </form>
    );
  };

// Styled component for blurred background
const BlurredBackground = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(5px)',
});

const CountdownModal = () => {
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const targetDate = new Date('2024-05-03T12:00:00');
      const currentDate = new Date();
      const timeDiff = targetDate - currentDate;

      if (timeDiff > 0) {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setRemainingTime(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      } else {
        clearInterval(intervalId);
        setRemainingTime('The Site Will Launch Soon');
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
<Modal
  open={true} // Modal is always open
  aria-labelledby="countdown-modal-title"
  aria-describedby="countdown-modal-description"
  disablePortal

>
  <BlurredBackground>
    <Paper
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: '90%', // Set width to 95%
        maxWidth: '500px', // Optional: Set max width to ensure it doesn't become too wide on larger screens
        maxHeight: '80vh',
        overflowY: 'auto',

      }}
    >
      <Container sx={{ color: 'white', backgroundColor: '#004AAD', padding: '50px', width: '100%' }}>
        <Typography variant="h4" id="countdown-modal-title" gutterBottom paddingTop="10px">
          Countdown to Launch
        </Typography>
        <Typography variant="h6" id="countdown-modal-description">
          {remainingTime}
        </Typography>
      </Container>
      <br />
      <Container padding="10px">
        <ContactForm />
      </Container>
    </Paper>
  </BlurredBackground>
</Modal>

  );
};

export default CountdownModal;
