import * as React from "react";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid  from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, CardActions } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import { Paper } from "@mui/material";

import Contract_Law from './img/Contract_Law.jpg';
import Tort_Law from './img/Tort_Law.jpg';
import Business_Law from './img/Business_Law.jpg';
import Constitutional_Law from './img/Constitutional_Law.jpg';
import Retained_EU_Law from './img/Retained_EU_Law.jpg';
import Dispute_Resolution from './img/Dispute_Resolution.jpg';
import Legal_Service from './img/Legal_Service.jpg';
import Legal_Systems from './img/Legal_Systems.jpg';
import Ethics from './img/Ethics.jpg';
import Human_Rights_Act from './img/Human_Rights_Act.jpg';
import Business_Finance from './img/Business_Finance.jpg';

import firebase from './firebase'; 
import AccessDialog from "./AccessDialog";


// const baseUrl = process.env.PUBLIC_URL;


const imagePaths = {
    "Contract_Law.jpg": Contract_Law,
    "Tort_Law.jpg": Tort_Law,
    "Business_Law.jpg": Business_Law,
    "Constitutional_Law.jpg": Constitutional_Law,
    "Retained_EU_Law.jpg": Retained_EU_Law,
    "Dispute_Resolution.jpg": Dispute_Resolution,
    "Legal_Service.jpg": Legal_Service,
    "Legal_Systems.jpg": Legal_Systems,
    "Ethics.jpg": Ethics,
    "Human_Rights_Act.jpg": Human_Rights_Act,
    "Business_Finance.jpg": Business_Finance,
};

const jsonData = require("./FLK1LearningMaterial.json");


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: '100%',
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: '100%',
  },
  "&:hover, &.Mui-focusVisible": {
    "& .MuiImageBackdrop-root": {
      opacity: 0.9,
      backgroundColor: "#004AAD",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "4px",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  borderRadius: "4px",
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.45,
  transition: theme.transitions.create("opacity"),
  borderRadius: "4px",
}));

export default function FLK1() {


  const [openFLK1Quiz, setOpenQuiz] = React.useState(false);
  const [openFLK1LearningMaterial, setOpenLearningMaterial] = React.useState(false);

  const [userId, setUserId] = React.useState('');
  const [productName, setProductName] = React.useState('');

  const [openAccessDialog, setOpenAccessDialog] = React.useState(false);

 
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        const userRef = firebase.database().ref("users/" + user.uid);
        userRef.on("value", (snapshot) => {
          const userVal = snapshot.val();
        });
      } else {
        setUserId('');
        setProductName(''); // Ensure productName is initialized with an empty string
      }
    });
  }, []);

 const checkout = (product)=>{
   fetch("https://lawschoolcompass.com/8767/api/v1/create-product-checkout-session",
   {
     method:'POST',
     headers:{
       'content-Type':'application/json'
     },
     mode:'cors',
     body:JSON.stringify({product: product, customerId: userId})
   })
     .then((res)=>{
       if(res.ok) return res.json();
       console.log(res);
       return res.json().then((json)=> Promise.reject(json));
     }
     ).then(({session})=>{
         window.location = session.url;
     })
     .catch((error)=>{
       console.error(error.message)
     })
   }

  const handleClickOpenFLK1Quiz = () => {
    setOpenQuiz(true);
  };
  const handleClickOpenFLK1LearningMaterial = () => {
    setOpenLearningMaterial(true);
  };
  const handleCloseFLK1Quiz = () => {
    setOpenQuiz(false);
  };
  const handleCloseFLK1LearningMaterial = () => {
    setOpenLearningMaterial(false);
  };
  const handleOpenAccessDialog = () => {
    setOpenAccessDialog(true);
  };

  const handleCloseAccessDialog = () => {
    setOpenAccessDialog(false);
  };

  return (
    <div style={{}}>
              <div class="text-center" style={{marginBottom:'6%'}}>
              <Typography gutterBottom variant="h4" component="div">Functional Legal Knowledge 1</Typography>
        <Typography variant="body1" style={{ marginLeft: "7%", marginRight: "7%" }}>
        The FLK 1 is the first of the two SQE1 exams. It covers the following topics:  
<li>Retained EU Law</li>
<li>Dispute Resolution (Coming soon) </li>
<li>Business Law </li>
<li>Business Finance (Coming soon) </li>
<li>Tort Law</li>
<li>Human Rights Act </li>
<li>Constitutional Law</li>
<li>Legal Services </li>
<li>Ethics (Covered on both exams) </li>
<li>Contract Law</li>
<li>Legal Systems of England and Wales </li>
We have created accessible notes and MCQs for each of the above topics, in order to prepare you for your exams.        </Typography>

      </div>
      
      <Grid container spacing={2} style={{width:'98%', marginLeft:'1%', marginRight:'1%', }}>
      <Grid item xs={12} sm={12} md={6} style={{borderRadius: '4px', padding:0, marginBottom:'3%',}}>
        <Card sx={{width:'95%', marginLeft:'2%', marginRight:'2%'}}>
        <CardContent>
          <Typography variant="h5">FLK1 Topics Quiz</Typography>
          <br/>
          <Typography variant="body2">
            Attempt a free 5 question multiple choice quiz, with no timer, to test your
            knowledge on FLK1 topics, in preperation for your SQE1 exam
          </Typography>
        </CardContent>
        <CardActionArea>
          <CardActions onClick={handleClickOpenFLK1Quiz}>
            <Button onClick={handleClickOpenFLK1Quiz}>
              Click here to choose a topic for the quiz
            </Button>
          </CardActions>
        </CardActionArea>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={6} style={{borderRadius: '4px', padding:0, marginBottom:'3%',}}>
        <Card sx={{width:'95%', marginLeft:'2%', marginRight:'2%'}}>
        <CardContent>
          <Typography variant="h5">FLK1 Learning Material</Typography>
          <br/>
          <Typography variant="body2">
            Use these learning material to enhance your Functional Legal Knowledge
          </Typography>
        </CardContent>
        <CardActionArea>
          <CardActions onClick={handleClickOpenFLK1LearningMaterial}>
            <Button onClick={handleClickOpenFLK1LearningMaterial}>
              Click here to view all the units we cover
            </Button>
          </CardActions>
        </CardActionArea>
        </Card>
      </Grid>

      </Grid>

      <Dialog
        fullScreen
        open={openFLK1Quiz}
        onClose={handleCloseFLK1Quiz}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#004AAD" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              FLK1 Topics Quiz
            </Typography>

            <IconButton
              color="inherit"
              onClick={handleCloseFLK1Quiz}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

<br/>

        <Grid container spacing={2} sx={{width:'96%', marginLeft:'1%', marginRight:'1%'}}>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>
        <ImageButton
          focusRipple
          key={'CONTRACT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/ContractLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Contract_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CONTRACT LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>
        <ImageButton
          focusRipple
          key={'TORT LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/TortLawQuiz/";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Tort_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
            TORT LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'BUSINESS LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/BusinessLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Business_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      BUSINESS LAW
            </Image>
          </ImageButton>
        </Grid>

        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'CONSTITUTIONAL LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/ConstitutionalLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Constitutional_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CONSTITUTIONAL LAW
            </Image>
          </ImageButton>

        </Grid>

        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'RETAINED EU LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/RetainedEULaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Retained_EU_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      RETAINED EU LAW
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'DISPUTE RESOLUTION'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/DisputeResolution";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Dispute_Resolution })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      DISPUTE RESOLUTION
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'LEGAL SERVICE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/LegalService";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Legal_Service})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LEGAL SERVICE
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'LEGAL SYSTEMS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/LegalService";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Legal_Systems })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LEGAL SYSTEMS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'ETHICS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/Ethics";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Ethics })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      ETHICS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'HUMAN RIGHTS ACT'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/HumanRightsAct";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Human_Rights_Act })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      HUMAN RIGHTS ACT
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{height:200}}>

        <ImageButton
          focusRipple
          key={'BUSINESS FINANCE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK1/BusinessFinance";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Business_Finance })`,
          borderRadius: "4px",
          filter: 'grayscale(100%)',
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
        BUSINESS FINANCE
      {/*BUSINESS FINANCE*/}
            </Image>
          </ImageButton>

        </Grid> 
      </Grid>
        <br/>
      </Dialog>

      <Dialog
  fullScreen
  open={openFLK1LearningMaterial}
  onClose={handleCloseFLK1LearningMaterial}
>
  <AppBar sx={{ position: "relative", backgroundColor: "#004AAD" }}>
    <Toolbar>
      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        FLK1 Learning Material
      </Typography>
      <Button variant="outline" sx={{ flex: 1, color:'white' }} onClick={handleOpenAccessDialog}>
      Buy these within your account dashboard
      </Button>
      <AccessDialog open={openAccessDialog} onClose={handleCloseAccessDialog}/>
      <IconButton
        color="inherit"
        onClick={handleCloseFLK1LearningMaterial}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  </AppBar>

  <Box sx={{ p: 2 }}>
    {jsonData.map((item, index) => (
      <Paper
        key={index}
        elevation={4}
        sx={{ mb: 2, height: "auto", display: "flex", alignItems: "center" }}
      >
        <Card sx={{ display: "flex", flexGrow: 1 }}>
          <CardMedia
            component="img"
            sx={{ width: 150, flexShrink: 0, borderRadius: "4px" }}
            image={imagePaths[item.image]}
            alt={item.name}
          />
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <CardContent sx={{ flex: "1 1 auto" }}>
              <Typography variant="h5">{item.name}</Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {item.price}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description.length === 0 ? (
                  <p>Stay tuned</p>
                ) : (
                  <ul>
                    {item.description.map((line, innerIndex) => (
                      <li key={innerIndex}>{line}</li>
                    ))}
                  </ul>
                )}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Paper>
    ))}
  </Box>
</Dialog>

<br/>

    </div>
  );
}
