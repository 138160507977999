// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// }
const firebaseConfig = {
    apiKey: "AIzaSyDvljbAEchVyjkV7fFf66c5J52fKxfib_k",
    authDomain: "law-quiz-fd166.firebaseapp.com",
    databaseURL: "https://law-quiz-fd166-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "law-quiz-fd166",
    storageBucket: "law-quiz-fd166.appspot.com",
    messagingSenderId: "969212208472",
    appId: "1:969212208472:web:7c0d78fe4ea6916075312d",
    measurementId: "G-BST6WLCFFH"
  };

// Initialize Firebase
if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig)
}
export default firebase