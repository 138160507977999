import React from 'react';
import { Typography, Container } from '@mui/material';

const SocialMediaPolicy = () => {
  return (
    <Container className='text-center' style={{marginLeft:'4%', marginRight:'4%'}}>
      <Typography variant="h4" gutterBottom>
        Social Media Policy
      </Typography>
      <Typography variant="body1" paragraph>
      <strong>Social Media and Business Policy (SMBP)</strong>
      <br/>

1.0 Policy Statement
<br/>
1.1 We recognise the unique opportunities presented by the internet and social media platforms, such as Twitter, to engage in interactive discussions and share information with students, prospective students, the public, and stakeholders. However, the use of social media carries legal, regulatory, and reputational risks, and it is essential to manage these effectively.
<br/>
1.2 To mitigate risks and ensure the appropriate use of our social media channels, all users ("Users") are expected to adhere to this policy.
<br/>
1.3 In this policy, "the Community" refers to any social media used by Law School Compass, and references to "Law School Compass" include Law School Compass Limited. 
<br/>
2.0 Scope and Purpose of the SMBP
<br/>
2.1 This policy governs the use of all forms of social media, including but not limited to Meta, and subsidiary products such as instagram and Tiktok  and internet postings like blogs made using our official social media channels (e.g., Law School Compass Facebook page). This includes any functionality within the VLE enabling interactions with other Law School Compass students.
<br/>
2.2 The SMBP applies to all Community use, irrespective of office hours or equipment used, either Law School Compass's or individuals'.
<br/>
2.3 Access to and use of the Community are subject to this SMBP. By using the Community, users acknowledge reading and accepting this policy.
<br/>
2.4 Users outside the UK should be aware that different laws may apply, and they should use the Community only if they accept this position.
<br/>
2.5 Suspected breaches of this SMBP may require cooperation in investigations, including providing relevant passwords and login details.
<br/>
2.6 Users may be asked to remove social media postings breaching this policy, and failure to comply may result in legal action.
<br/>
2.7 We reserve the right to update this SMBP, and Users are responsible for staying informed about its terms.
<br/>
3.0 Compliance with Related Policies and Agreements
<br/>
3.1 Social media use should not violate other rules or policies. Prohibited actions include breaches of IT policies, regulatory body rules, confidentiality, intellectual property, academic rules, defamation, anti-harassment policies, discrimination, data protection, privacy policies, and other laws or standards.
<br/>
4.0 Social Media Networks' Terms and Conditions
<br/>
4.1 Specific social network terms apply to Law School Compass profiles hosted on social media platforms. Users should familiarise themselves with these terms, and in case of conflict with this SMBP, the social media channel's terms prevail.
<br/>
5.0 Moderation of the Community
<br/>
5.1 We aim for open, caring, and respectful dialogue in the Community. Monitoring occurs during normal UK business hours, and we reserve the right to remove or disable access to any Contribution violating this SMBP without warning.
<br/>
5.2 Users must ensure their Contributions comply with this SMBP and Acceptable Use Standards.
<br/>
5.3 We are not legally responsible for third-party materials, and moderation may be suspended at any time.
<br/>
6.0 Important Points to be Aware of
<br/>
6.1 Individual views expressed do not necessarily reflect those of Law School Compass.
<br/>
6.2 Advice or information posted should be verified for accuracy.
<br/>
6.3 Automatic follows, re-tweets, or sharing do not imply endorsement.
<br/>
7.0 Minors
<br/>
7.1 The Community is for users aged 18 or more. Minors need parental/guardian permission, and parents are encouraged to discuss online safety and monitor their child's use.
<br/>
8.0 Acceptable Use Standards
<br/>
8.1 Users agree to accurate, respectful, and lawful use of the Community, complying with all applicable laws.
<br/>
8.2 Contributions must not infringe intellectual property, harass, cause harm, encourage unlawful acts, misrepresent identity, or contain advertising.
<br/>
9.0 Copyright
<br/>
9.1 Users grant a non-exclusive perpetual licence for Contributions, and we may exercise copyright rights as described. Users must ensure Contributions do not infringe copyrights.
<br/>
10.0 Data Protection
<br/>
10.1 Personal data submission is governed by our data protection and privacy policies.
<br/>
1.0 Complaints
<br/>
11.1 Complaints about Contributions can be sent to info.admin@lawschoolcompass.com, outlining the reason and location of the disputed Contribution.
<br/>
11.2 We may request additional information and will inform Users of the review outcome promptly.
<br/>
12.0 Governing Law and Jurisdiction
<br/>
12.1 This SMBP is governed by English and Welsh laws, with exclusive jurisdiction of the English and Welsh courts.
<br/>
12.2 If any term is found illegal, the remainder of the SMBP remains in force.
<br/>
Date last updated: March 2024
      </Typography>
    </Container>
  );
};

export default SocialMediaPolicy;
