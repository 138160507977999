import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './App.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Paper, CardMedia } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import sqe_flk1 from './img/SQE_FLK1.jpg';
import sqe_flk2 from './img/SQE_FLK2.jpg';
import llb from './img/LLB.jpg';
import CountdownModal from './Countdown';
import PoliciesSnackBar from './PoliciesSnackBar';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




const Home = () => {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
   setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  return (
    <div className="App">

{/*<CountdownModal/>*/}

      <div class="text-center">
      <Typography variant="h3" fontWeight='bold' gutterBottom style={{ marginLeft: "1%", marginRight: "1%" }}>Law School Compass</Typography>
      <Typography variant="h6" gutterBottom style={{ marginLeft: "2%", marginRight: "2%" }}>Your go to resource for legal education & support!</Typography>
        <p style={{ marginLeft: "6%", marginRight: "6%" }}>
          <small>
          This law teaching platform provides comprehensive legal education through interactive modules, resources, and expert guidance. Tailored to aspiring legal professionals, it offers a dynamic learning experience to deepen understanding and enhance skills in various legal domains.
</small>
          </p>
      </div>

      <Box sx={{ width: '100%',}}>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
  <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
    <Tab label="SQE1 FLK1" {...a11yProps(0)} sx={{ flex: 1, minWidth: '33.33%' }} />
    <Tab label="SQE1 FLK2" {...a11yProps(1)} sx={{ flex: 1, minWidth: '33.33%' }} />
    <Tab label="LLB" {...a11yProps(2)} sx={{ flex: 1, minWidth: '33.33%' }} />
  </Tabs>
</Box>


<SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
      <CustomTabPanel value={value} index={0}>
                    <Paper elevation={4}>
              <Card>
                <CardActionArea style={{cursor:"default"}}>
                  <CardMedia component="img" image={sqe_flk1} height={260}/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      FLK1
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    FLK1 is a foundational module in the Solicitors Qualifying Examination (SQE) assessing foundational legal knowledge. It covers essential topics such as contract, tort, and criminal law, crucial for aspiring solicitors.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions component={RouterLink} to="/FLK1">
                  <Button size="small" color="primary" component={RouterLink} to="/FLK1">
                    View More Information & Test Your Knowledge
                  </Button>
                </CardActions>
              </Card>
            </Paper>

      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
                    <Paper elevation={4}>
              <Card>
                <CardActionArea style={{cursor:"default"}}>
                  <CardMedia component="img" image={sqe_flk2} height="260"/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      FLK2
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    FLK2 is a core module in the Solicitors Qualifying Examination (SQE) focusing on key legal principles and procedures. It covers areas such as property, equity, and trusts, essential for future solicitors.                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions component={RouterLink} to="/FLK2">
                  <Button size="small" color="primary" component={RouterLink} to="/FLK2">
                    View More Information & Test Your Knowledge
                  </Button>
                </CardActions>
              </Card>
            </Paper>

      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        
            <Paper elevation={4}>
              <Card>
                <CardActionArea style={{cursor:"default"}}>
                  <CardMedia component="img" image={llb} height="260"/>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      LLB
                    </Typography>
                    <Typography variant="body2" color="text.secondary">

                    LLB (Bachelor of Laws) is an undergraduate degree program providing comprehensive legal education. It encompasses various aspects of law, including jurisprudence, contract law, criminal law, and constitutional law, preparing students for legal careers or further studies.                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions component={RouterLink} to="/LLB" disabled>
                  <Button size="small" color="primary" component={RouterLink} to="/LLB" disabled>
                    MORE INFORMATION COMING SOON{/* View More Information & Test Your Knowledge */}
                  </Button>
                </CardActions>
              </Card>
            </Paper>

      </CustomTabPanel>
      </SwipeableViews>

      <PoliciesSnackBar/> 
      
</Box>   

</div>
  );
}


export default Home;
