import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import firebase from './firebase'; 

function AccessDialog({ open, onClose }) {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUserLoggedIn(!!user); // Update userLoggedIn state based on user existence
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Access Learning Material</DialogTitle>
      <DialogContent>
        <Typography>
        If you have a Gold / Silver / Bronze Account, you will already have access to the learning material in their dashboard.
        </Typography>
        If you have a Free Account, you need to purchase the learning material from your dashboard and save the link to the learning material after making the purchase.

        <Typography>
            </Typography>
      </DialogContent>
      <DialogActions>
        {userLoggedIn ? (
          <Button component={RouterLink} to="/dashboard" color="primary">
            Go to Dashboard
          </Button>
        ) : (
          <>
            <Button component={RouterLink} to="/login" color="primary">
              Log In
            </Button>
            <Button component={RouterLink} to="/signup" color="primary">
              Sign Up
            </Button>
          </>
        )}
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AccessDialog;
