import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'; // Importing RouterLink for navigation
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container maxWidth="md" sx={{marginBottom:'8px'}}>
      <Typography variant="h4" align="center" gutterBottom>
        FAQ
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is the Solicitors Qualification Exam?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The Solicitors Qualification Exam (SQE) is the new form of assessment put forward by the Solicitor’s
            Regulatory Authority. It consists of two assessments, called Functioning legal knowledge assessments (FLK 1
            and FLK 2). Each exam consists of 180 questions and is sat in a Person test center.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is the Law School Compass?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           We are a private tuition company which aims to provide accessible tutoring at an affordable price.
            The main point being, we’ve been in the position you are in so we know how to prepare for both LLB and SQE
            exams.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">How can your tuition services help me?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our SQE tutors are experienced, they have sat the SQE exam and know how to prepare for it. They have
            also undertaken the LLB. There’s no better way to learn than from people who have been in your shoes!
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Which units do you cover?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please see the <Link to='/FLK1' component={RouterLink}>FLK1</Link>/ <Link component={RouterLink} to='/FLK2'>FLK2</Link>/ <Link component={RouterLink} to='/LLB'>LLB</Link> section on our website to see which units we cover. Private tuition
            is offered on all units.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What is your cancellation policy for tuition services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We expect clients to cancel or reschedule their sessions with at least 48 hours notice. Sessions
            which are canceled with less than 48 hours notice may not be eligible for a refund. Terms and conditions
            apply.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">What are your mocks?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer both virtual and in person SQE mocks as part of our tuition services.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">How do I contact you?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can contact us via email at <a href='mailto:info.admin@lawschoolcompass.com'>info.admin@lawschoolcompass.com</a>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQ;
