import React, { useState, useEffect } from 'react';
import { Box, Button, Container, LinearProgress, Typography, Alert, Rating, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, RadioGroup, FormControl, Radio, Slider } from '@mui/material';
import firebase from '../firebase'; // Assuming firebase.js contains Firebase configuration and initialization

// Import questions based on plan type
const getQuestionFile = (planType) => {
  
  const questions = require("./BusinessLaw.json")
  let allQuestions = null;
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  if (planType === "Gold" || planType === "Silver") {
     allQuestions = shuffleArray(questions); allQuestions.slice(0,60)
  } else if (planType === "Bronze Practice") {
    allQuestions = shuffleArray(questions); allQuestions.slice(0,20)
  } else {
    allQuestions = questions.slice(0, 5);
   }
  return allQuestions;
};

function Quiz() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [progress, setProgress] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(true);
  const [userRating, setUserRating] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [questionCount, setQuestionCount] = useState(5);
  const [planType, setPlanType] = useState('');

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userRef = firebase.database().ref("users/" + user.uid);
        userRef.on("value", (snapshot) => {
          const userVal = snapshot.val();
          if (userVal) {
            setPlanType(userVal.subscription.planType);
          }
        });
      } else {
        setPlanType('');
      }
    });
  }, []);

  const handleAccountDialogClose = () => {
    console.log("Account dialog closed.");
    setAccountDialogOpen(false);
  };

  const handleCheckAnswer = () => {
    const userAnswer = selectedAnswer;
    if (userAnswer) {
      const correctAnswer = questions[currentQuestionIndex].answer;
      if (userAnswer === correctAnswer) {
        showAlertMessage('Correct!', 'success');
        setScore(score + 1);
      } else {
        showAlertMessage('Incorrect', 'error');
      }
      setAnswerChecked(true);
    } else {
      setShowAlert(true);
      setAlertMessage('Please select an answer.');
      setAlertType('warning');
    }
  };

  const handleOptionChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const showAlertMessage = (message, type) => {
    setShowAlert(true);
    setAlertMessage(message);
    setAlertType(type);
    if (message === 'Correct!' || message === 'Incorrect') {
      setShowExplanation(true);
    }
  };

  const [showExplanation, setShowExplanation] = useState(false);

  const handleNextQuestion = () => {
    setShowAlert(false);
    setShowExplanation(false);
    setAnswerChecked(false);
    setSelectedAnswer(null);
    if (currentQuestionIndex < questionCount - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentQuestionNumber(currentQuestionNumber + 1);
      setProgress(((currentQuestionIndex + 1) / questionCount) * 100);
    } else {
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  
  const handleRetry = () => {
    setCurrentQuestionIndex(0);
    setCurrentQuestionNumber(1);
    setProgress(0);
    setDialogOpen(false);
    setScore(0);
    setSelectedAnswer(null);
    setAnswerChecked(false);
  };

  const calculateUserRating = () => {
    const scorePercentage = (score / questionCount) * 100;
    if (scorePercentage >= 90) {
      setUserRating(5);
    } else if (scorePercentage >= 75) {
      setUserRating(4);
    } else if (scorePercentage >= 55) {
      setUserRating(3);
    } else if (scorePercentage >= 35) {
      setUserRating(2);
    } else {
      setUserRating(1);
    }
  };

  useEffect(() => {
    calculateUserRating();
  }, [score, questionCount]);

  const allQuestions = getQuestionFile(planType);
  const questions = allQuestions;

  return (
    <Container maxWidth="md" sx={{ mt: 3, minHeight: "78vh", display: "flex", flexDirection: "column" }}>
      <div className="jumbotron text-center">
        <Typography variant="h4" gutterBottom>Practice Quiz</Typography>
      </div>
      {(planType === "Silver" || planType === "Gold" || planType === "Bronze Practice") && (
        <Dialog open={accountDialogOpen} onClose={handleAccountDialogClose}>
          <DialogTitle>{`Quiz with ${questionCount} Questions`}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {`with your ${planType} Account, you can adjust the slider to select the number of questions for the quiz.`}
            </Typography>
            <Slider
              aria-label="Number of Questions"
              value={questionCount}
              onChange={(event, newValue) => setQuestionCount(newValue)}
              step={5}
              min={5}
              max={planType === "Silver" ? 45 : planType === "Bronze Practice" ? 20 : 60}
              marks
              valueLabelDisplay="auto"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAccountDialogClose} color="primary">
              Start Quiz
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Typography variant="h5" gutterBottom id="questionNo">Question {currentQuestionNumber}</Typography>
      <LinearProgress variant="determinate" value={progress} sx={{ mb: 2 }} />
      <Typography variant="body1" gutterBottom>Select an answer and click "Check Answer" to validate</Typography>
      <form id="quiz">
        <Box>
          <Typography variant="h6" gutterBottom>
            {questions[currentQuestionIndex].question}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup name="answer" value={selectedAnswer} onChange={handleOptionChange}>
              {questions[currentQuestionIndex].options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={<Radio value={option} disabled={answerChecked} />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </form>
      <Button variant="contained" color="primary" onClick={handleCheckAnswer} disabled={answerChecked} sx={{ mt: 2 }}>
        Check Answer
      </Button>
      <Box sx={{ mt: 2, flexGrow: 1 }}>
        {showAlert && (
          <Alert severity={alertType} sx={{ mt: 2, flexGrow: 1 }}>
            {alertMessage}
            {showExplanation && (
              <>
                <br />
                {questions[currentQuestionIndex].explanation}
                <br />
                <Button onClick={handleNextQuestion} color="inherit" size="small" style={{ width: "100%" }}>
                  Next
                </Button>
              </>
            )}
          </Alert>
        )}
      </Box>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Quiz Completed</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>Your rating:</Typography>
          <Rating
            name="user-rating"
            value={userRating}
            readOnly
          />
          <Typography variant="body1" gutterBottom>{`Your score: ${score}/${questionCount}`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRetry} color="primary">
            Retry
          </Button>
        </DialogActions>
      </Dialog>
      {currentQuestionIndex === questionCount && (
        <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} sx={{ mt: 2 }}>
          Finish
        </Button>
      )}
    </Container>
  );
}

export default Quiz;
