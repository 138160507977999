import React, { useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom'; // Importing RouterLink for navigation
import Link from '@mui/material/Link';
import { Grid, Paper, Dialog } from '@mui/material';

import firebase from './firebase'; 


function SignUp() {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
    // const passwordErrorRef= useRef(null);
    // const confirmPasswordErrorRef = useRef(null);

  // State variables for form fields and error messages
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    // const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };



  // Function to handle form submission
  const handleSubmit = async(event) => {
    event.preventDefault();

    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    // Validate password
    if (!isValidPassword(password)) {
      setPasswordError('Password must have at least 1 capital letter, 1 number, 1 special character and be minimum 8 characters.');
      return;
    } else {
      setPasswordError('');
    }

    // Validate password match
    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    } else {
      setConfirmPasswordError('');
    }

        // Move to the next step
        //setActiveStep(activeStep + 1);
        const fullName = `${firstName} ${lastName}`
      
        try{
        const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
        
        if(response.user){
          await response.user.updateProfile({
            displayName: fullName
          });
          const uid = response.user.uid;
          const userRef = firebase.database().ref('users/' + uid);
          await userRef.set({
            uid:uid,
            firstName:firstName,
            lastName:lastName,
            email:email,
            subscription: {
              sessionId: null,
              planId:'#',
              planType: 'Free',
              planStartDate: '#',
              planEndDate: '#',
              planDuration: '#'
          },
          flk1Mock:{
            partOneAvailable:"no",
            partTwoAvailable:"no",
            partOneResults:[{score:0}],
            partTwoResults:[{score:0}]
          }
          })
        }
          
        console.log("User created and profile updated successfully!");

        // setFirstName('');
        // setLastName('');
        // setEmail('');
        // setPassword('');
        // setConfirmPassword('');
        // setPasswordError('');
        // setConfirmPasswordError('');

        alert("Sign up completed!");
        //handleClickOpen();
        setOpen(true);
      } catch (error){
          console.error("sign up failed!:", error.message);
          alert("Sign up failed"); 
        }
      };

  // Function to check if the password meets the criteria
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  }; 


  function Form(){
    
    return (
      <form textAlign="center" style={{justifyContent:'center', textAlign:'center',}}>
        <TextField
          label="First Name"
          variant="standard"
          fullWidth
          margin="normal"
          inputRef={firstNameRef}     
          required
          sx={{width:'80%', }}
        />
        <TextField
          label="Last Name"
          variant="standard"
          fullWidth
          margin="normal"
          inputRef={lastNameRef}
          required
          sx={{width:'80%'}}
        />
        <TextField
          label="Email Address"
          variant="standard"
          fullWidth
          margin="normal"
          type="email"
          inputRef={emailRef}
          required
          sx={{width:'80%'}}
        />
        <TextField
          label="Password"
          variant="standard"
          fullWidth
          margin="normal"
          type="password"
          inputRef={passwordRef}        
          error={passwordError !== ''}
          helperText={passwordError}
          required
          sx={{width:'80%'}}
        />
        <TextField
          label="Confirm Password"
          variant="standard"
          fullWidth
          margin="normal"
          type="password"
          inputRef={confirmPasswordRef}       
          error={confirmPasswordError !== ''}
          helperText={confirmPasswordError}
          required
          sx={{width:'80%'}}
        />
        <br/>
        <br/>
        {/* <Button type="submit" variant="contained" sx={{backgroundColor:'#004AAD'}} onClick={handleClickOpen}> */}
        <Button type="button" variant="contained" sx={{backgroundColor:'#004AAD'}} onClick={handleSubmit}>
          Submit
        </Button>

      <Dialog open={open}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h5" align="center" gutterBottom>
            Sign Up Completed
            </Typography>
              <Typography variant="body2" align="center" style={{ marginTop: '10px' }}>
              Thanks for signing up. <Link component={RouterLink} to="/login">Log in</Link> to upgrade your free account and enjoy all the resources available to you.
              </Typography>
          </Paper>
        </Grid>
      </Grid>
      </Dialog>

      </form>
    );
  };


  return (
    <Box sx={{minHeight:'55vh',margin: 'auto', marginTop: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Sign Up
      </Typography>
      <Box mb={2} textAlign="center">
          <Typography variant="body2">
            Already have an account?{' '}
            <Link component={RouterLink} to="/login" color="primary">
              Log In
            </Link>
          </Typography>
        </Box>
        <Form />
    </Box>
  );
};

export default SignUp;
