import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CardActionArea, CardActions } from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";

import firebase from './firebase'; 
import AccessDialog from "./AccessDialog";

// Import FLK2 images
import Criminal_Law from './img/Criminal_Law.jpg';
import Criminal_Practice from './img/Criminal_Practice.jpg';
import Property_Practice from './img/Property_Practice.jpg';
import Land_Law from './img/Land_Law.jpg';
import Trusts from './img/Trusts.jpg';
import Solicitors_Accounts from './img/Solicitors_Accounts.jpg';
import Wills_and_Administration_of_Estates from './img/Wills_and_Administration_of_Estates.jpg';
import Inheritance_Tax from './img/Inheritance_Tax.jpg';

const imagePaths = {
  'Criminal_Law.jpg': Criminal_Law,
  'Criminal_Practice.jpg': Criminal_Practice,
  'Property_Practice.jpg': Property_Practice,
  'Land_Law.jpg': Land_Law,
  'Trusts.jpg': Trusts,
  'Solicitors_Accounts.jpg': Solicitors_Accounts,
  'Wills_and_Administration_of_Estates.jpg': Wills_and_Administration_of_Estates,
  'Inheritance_Tax.jpg': Inheritance_Tax,
};
const jsonData = require("./FLK2LearningMaterial.json");

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 300,
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    "& .MuiImageBackdrop-root": {
      opacity: 0.9,
      backgroundColor: "#004AAD",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "4px",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  borderRadius: "4px",
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.45,
  transition: theme.transitions.create("opacity"),
  borderRadius: "4px",
}));


const FLK2 = () => {
  const [openFLK2Quiz, setOpenFLK2Quiz] = React.useState(false);
  const [openFLK2LearningMaterial, setOpenFLK2LearningMaterial] = React.useState(false);

  const [userId, setUserId] = React.useState('');
  const [productName, setProductName] = React.useState('');

  const [openAccessDialog, setOpenAccessDialog] = React.useState(false);

 
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        const userRef = firebase.database().ref("users/" + user.uid);
        userRef.on("value", (snapshot) => {
          const userVal = snapshot.val();
        });
      } else {
        setUserId('');
        setProductName(''); // Ensure productName is initialized with an empty string
      }
    });
  }, []);

  const handleClickOpenFLK2Quiz = () => {
    setOpenFLK2Quiz(true);
  };

  const handleClickOpenFLK2LearningMaterial = () => {
    setOpenFLK2LearningMaterial(true);
  };

  const handleCloseFLK2Quiz = () => {
    setOpenFLK2Quiz(false);
  };

  const handleCloseFLK2LearningMaterial = () => {
    setOpenFLK2LearningMaterial(false);
  };

  const handleOpenAccessDialog = () => {
    setOpenAccessDialog(true);
  };

  const handleCloseAccessDialog = () => {
    setOpenAccessDialog(false);
  };

  return (
    <div style={{}}>
      <div class="text-center" style={{ marginBottom: '6%' }}>
        <Typography gutterBottom variant="h4" component="div">
          Functional Legal Knowledge 2
        </Typography>
        <Typography variant="body1" style={{ marginLeft: '7%', marginRight: '7%' }}>
        The FLK 2 is the second  of the two SQE1 exams, it covers the following topics:   <li>Retained EU Law</li>
 <li>Wills and Administration of Trusts </li>
 <li>Property Practice </li>
 <li>Land Law</li>
 <li>Criminal Law</li>
 <li>Criminal Practice/ Litigation</li>
<li>Solicitors accounts</li>
<li>inheritance Tax  </li>
<li>Trusts </li>
We have created accessible notes and MCQs for each of the above topics, in order to prepare you for your exams.
        </Typography>
      </div>

      <Grid container spacing={2} style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
        <Grid item xs={12} sm={12} md={6} style={{ borderRadius: '4px', padding: 0, marginBottom: '3%' }}>
          <Card sx={{ width: '95%', marginLeft: '2%', marginRight: '2%' }}>
            <CardContent>
              <Typography variant="h5">FLK2 Topics Quiz</Typography>
              <br />
              <Typography variant="body2">
                Attempt a free 5 question multiple choice quiz, with no timer, to test your knowledge on FLK2 topics, in preparation for your SQE1 exam
              </Typography>
            </CardContent>
            <CardActions onClick={handleClickOpenFLK2Quiz}>
              <Button onClick={handleClickOpenFLK2Quiz}>
                Click here to choose a topic for the quiz
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} style={{ borderRadius: '4px', padding: 0, marginBottom: '3%' }}>
          <Card sx={{ width: '95%', marginLeft: '2%', marginRight: '2%' }}>
            <CardContent>
              <Typography variant="h5">FLK2 Learning Material</Typography>
              <br />
              <Typography variant="body2">
                Use these learning material to enhance your Functional Legal Knowledge
              </Typography>
            </CardContent>
            <CardActions onClick={handleClickOpenFLK2LearningMaterial}>
              <Button onClick={handleClickOpenFLK2LearningMaterial}>
                Click here to view all the units we cover
              </Button>
            </CardActions>
          </Card>
        </Grid>

      </Grid>

      <Dialog fullScreen open={openFLK2Quiz} onClose={handleCloseFLK2Quiz}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#004AAD' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              FLK2 Topics Quiz
            </Typography>
            <IconButton color="inherit" onClick={handleCloseFLK2Quiz} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <br />
       
        <Grid container spacing={2} sx={{width:'96%', marginLeft:'1%', marginRight:'1%'}}>
        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'CRIMINAL LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/CriminalLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Criminal_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CRIMINAL LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'CRIMINAL PRACTICE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/CriminalPractice";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Criminal_Practice })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
            CRIMINAL PRACTICE
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'PROPERTY PRACTICE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/PropertyPractice";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Property_Practice })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      PROPERTY PRACTICE
            </Image>
          </ImageButton>
        </Grid>

        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'LAND LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/LandLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Land_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LAND LAW
            </Image>
          </ImageButton>

        </Grid>

        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'TRUSTS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/Trusts";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Trusts})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      TRUSTS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'SOLICITOTS ACCOUNTS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/SolicitorsAccounts";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Solicitors_Accounts })`,
          borderRadius: "4px",
          filter: 'grayscale(100%)',
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
       SOLICITORS ACCOUNTS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'WILLS AND ADMINISTRATION OF ESTATES'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/WillsAndAdministrationOfEstates";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Wills_and_Administration_of_Estates})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      WILLS & ADMINISTRATION OF ESTATES
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={6} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'INHERITANCE TAX'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/Quiz/FLK2/InheritanceTax";
          }} 
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Inheritance_Tax })`,
          borderRadius: "4px",
          filter: 'grayscale(100%)',
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      INHERITANCE TAX
            </Image>
          </ImageButton>

        </Grid>
        
      </Grid>
      <br/>
      </Dialog>

      <Dialog
  fullScreen
  open={openFLK2LearningMaterial}
  onClose={handleCloseFLK2LearningMaterial}
>
  <AppBar sx={{ position: "relative", backgroundColor: "#004AAD" }}>
    <Toolbar>
      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
        FLK2 Learning Material
      </Typography>
      <Button variant="text" sx={{ flex: 1, color:'white' }} onClick={handleOpenAccessDialog}>
      Buy these within your account dashboard
      </Button>
      <AccessDialog open={openAccessDialog} onClose={handleCloseAccessDialog}/>
      <IconButton
        color="inherit"
        onClick={handleCloseFLK2LearningMaterial}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  </AppBar>

  <Box sx={{ p: 2 }}>
    {jsonData.map((item, index) => (
      <Paper
        key={index}
        elevation={4}
        sx={{ mb: 2, height: "auto", display: "flex", alignItems: "center" }}
      >
        <Card sx={{ display: "flex", flexGrow: 1 }}>
          <CardMedia
            component="img"
            sx={{ width: 150, flexShrink: 0, borderRadius: "4px" }}
            image={imagePaths[item.image]}
            alt={item.name}
          />
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
            <CardContent sx={{ flex: "1 1 auto" }}>
              <Typography variant="h5">{item.name}</Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {item.price}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.description.length === 0 ? (
                  <p>Stay tuned</p>
                ) : (
                  <ul>
                    {item.description.map((line, innerIndex) => (
                      <li key={innerIndex}>{line}</li>
                    ))}
                  </ul>
                )}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Paper>
    ))}
  </Box>
</Dialog>
    </div>
  );
};

export default FLK2;
