import * as React from 'react';
import Grid from '@mui/material/Grid';
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

// Import FLK2 images
import Criminal_Law from '../img/Criminal_Law.jpg';
import Criminal_Practice from '../img/Criminal_Practice.jpg';
import Property_Practice from '../img/Property_Practice.jpg';
import Land_Law from '../img/Land_Law.jpg';
import Trusts from '../img/Trusts.jpg';
import Solicitors_Accounts from '../img/Solicitors_Accounts.jpg';
import Wills_and_Administration_of_Estates from '../img/Wills_and_Administration_of_Estates.jpg';
import Inheritance_Tax from '../img/Inheritance_Tax.jpg';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 300,
  borderRadius: "4px",
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    "& .MuiImageBackdrop-root": {
      opacity: 0.9,
      backgroundColor: "#004AAD",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: "4px",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  borderRadius: "4px",
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.45,
  transition: theme.transitions.create("opacity"),
  borderRadius: "4px",
}));

const FLK2 = ({open, onClose}) => {

  return (
    <div style={{}}>
       
        <Grid container spacing={2} sx={{width:'96%', marginLeft:'1%', marginRight:'1%'}}>
        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'CRIMINAL LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/CriminalLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Criminal_Law})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      CRIMINAL LAW
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>
        <ImageButton
          focusRipple
          key={'CRIMINAL PRACTICE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/CriminalPractice";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Criminal_Practice })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
            CRIMINAL PRACTICE
            </Image>
          </ImageButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'PROPERTY PRACTICE'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
           window.location.href = "/LearningMaterial/FLK2/PropertyPractice";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Property_Practice })`,
          borderRadius: "4px",
          filter: 'grayscale(100%)',
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      PROPERTY PRACTICE
            </Image>
          </ImageButton>
        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'LAND LAW'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/LandLaw";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Land_Law })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      LAND LAW
            </Image>
          </ImageButton>

        </Grid>

        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'TRUSTS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/Trusts";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Trusts})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      TRUSTS
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'SOLICITOR ACCOUNTS'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/SolicitorAccounts";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Solicitors_Accounts })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
        SOLICITOR ACCOUNTS 
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'WILLS AND ADMINISTRATION OF ESTATES'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/WillsAndAdministrationOfEstates";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Wills_and_Administration_of_Estates})`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      WILLS & ADMINISTRATION OF ESTATES
            </Image>
          </ImageButton>

        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{height:250}}>

        <ImageButton
          focusRipple
          key={'INHERITANCE TAX'}
          style={{
            width: "100%", height:"100%"
          }}
          sx={{ borderRadius: "4px" }}
          onClick={() => {
            window.location.href = "/LearningMaterial/FLK2/InheritanceTax";
          }}
        >          
        <ImageSrc
        style={{
          backgroundImage: `url(${Inheritance_Tax })`,
          borderRadius: "4px",
        }}
      />
      <ImageBackdrop className="MuiImageBackdrop-root" />
      <Image>
      INHERITANCE TAX
            </Image>
          </ImageButton>

        </Grid>
        
      </Grid>

    </div>
  );
};

export default FLK2;
